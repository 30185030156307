import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, DialogProps } from '@mui/material';
import { useContext } from 'react';
import { ConfirmModalContext } from '../contexts/ConfirmModalContext';

const ConfirmModalPresenter: React.FC = () => {
  const { isOpen, title, message, confirmLabel, cancelLabel, onConfirm, onCancel, closeConfirmModal } = useContext(ConfirmModalContext);
  const [maxWidth, _] = React.useState<DialogProps['maxWidth']>('sm');

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    closeConfirmModal();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    closeConfirmModal();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {(message || "").split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelLabel}</Button>
        <Button onClick={handleConfirm} autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModalPresenter;
