import React from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CenteredMainAreaWrapper, ContentsWrapper } from '@/components/ui/StyledComponents';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from "@/theme";

const ReminderPanel = styled(Box)`
  padding: 40px;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
    width: 100%;
    padding: 15px;
    border-radius: 0;
  }
`;

const ReminderTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
  height: 48px;
`;

interface PasswordReminderPresenterProps {
  form: UseFormReturn<{
    email: string;
  }>;
  onSubmit: (data: { email: string }) => Promise<void>;
  isSubmitted: boolean;
  onBackToLogin: () => void;
}

const PasswordReminderPresenter: React.FC<PasswordReminderPresenterProps> = ({
  form,
  onSubmit,
  isSubmitted,
  onBackToLogin,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors, isSubmitting } } = form;

  return (
    <ContentsWrapper>
      <CenteredMainAreaWrapper>
        <ReminderPanel>
          {/* t: パスワードをリセット */}
          <ReminderTitle variant="h5">{t('auth:resetPassword')}</ReminderTitle>

          {isSubmitted ? (
            <Box>
              <Alert severity="success" sx={{ mb: 3 }}>
                {/* t: パスワードリセットメールを送信しました */}
                {t('auth:passwordResetEmailSent')}
              </Alert>
              <Typography variant="body1" paragraph>
                {/* t: パスワードリセット用のメールを送信しました。メールに記載されているリンクからパスワードの再設定を行ってください。 */}
                {t('auth:passwordResetEmailSentDescription')}
              </Typography>
              <Typography variant="body2" paragraph>
                {/* t: メールが届かない場合は、迷惑メールフォルダをご確認いただくか、メールアドレスをご確認の上、再度お試しください。 */}
                {t('auth:checkSpamFolderOrTryAgain')}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={onBackToLogin}
                startIcon={<ArrowBackIcon />}
                fullWidth
                sx={{ mt: 2 }}
              >
                {/* t: ログイン画面に戻る */}
                {t('auth:backToLogin')}
              </Button>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1" paragraph>
                {/* t: アカウントに登録されているメールアドレスを入力してください。パスワードリセット用のリンクをメールで送信します。 */}
                {t('auth:enterEmailForPasswordReset')}
              </Typography>

              <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* t: メールアドレス */}
                <TextField
                  label={t('auth:email')}
                  fullWidth
                  margin="normal"
                  required
                  autoComplete="email"
                  autoFocus
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...register('email')}
                />

                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {/* t: リセットリンクを送信 */}
                  <SubmitButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    disabled={isSubmitting}
                  >
                    {t('auth:sendResetLink')}
                  </SubmitButton>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onBackToLogin}
                    startIcon={<ArrowBackIcon />}
                    fullWidth
                  >
                    {/* t: ログイン画面に戻る */}
                    {t('auth:backToLogin')}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </ReminderPanel>
      </CenteredMainAreaWrapper>
    </ContentsWrapper>
  );
};

export default PasswordReminderPresenter;
