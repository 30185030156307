import { z } from '@/lib/zod';
import i18next from 'i18next';

// パスワードバリデーション用の共通ロジック
export const createPasswordSchema = () => {
  return z.string()
    .min(8)
    .superRefine((password, ctx) => {
      // 使用可能な文字のみをチェック
      if (!(/^[A-Za-z\d@$!%*?&\-_]+$/.test(password))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          // t: パスワードに使用できない文字が含まれています。英字・数字・記号(@$!%*?&-_)のみ使用してください。
          message: i18next.t('auth:validationError.password.invalidChars'),
          path: [],
        });
      }

      // 各カテゴリ（小文字、大文字、数字、記号）の存在チェック
      let categories = 0;
      if (/[a-z]/.test(password)) categories += 1;
      if (/[A-Z]/.test(password)) categories += 1;
      if (/\d/.test(password)) categories += 1;
      if (/[@$!%*?&\-_]/.test(password)) categories += 1;

      if (categories < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          // t: パスワードは英大文字・英小文字・数字・記号(@$!%*?&-_)のうち2種類以上を含む必要があります
          message: i18next.t('auth:validationError.password.complexity'),
          path: [],
        });
      }
    });
};


// 利用規約同意のバリデーション用の共通ロジック
export const createTermsAgreementSchema = () => {
  return z.boolean()
    .superRefine((agreed, ctx) => {
      if (agreed !== true) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          // t: 利用規約とプライバシーポリシーに同意してください
          message: i18next.t('auth:validationError.terms.required'),
          path: [],
        });
      }
    });
};

