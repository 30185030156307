import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import messageModalReducer from './components/features/generic/messageModal.slice'
import toastReducer from './components/features/generic/toast.slice'
import authReducer from './components/features/auth/auth.slice';
import threadReducer from './components/features/thread/thread.slice';
import quickPromptReducer from './components/features/quickPrompt/quickPrompt.slice';
import dlpReducer from './components/features/dlp/dlp.slice';
import membershipReducer from './components/features/membership/membership.slice'
import membershipGroupReducer from './components/features/membership-group/membership-group.slice'
import newsReducer from './components/features/news/news.slice';
import reportReducer from './components/features/reports/report.slice';
import fileReducer from './components/features/file/file.slice';
// スーパーバイザー
import svTeamReducer from './components/features/supervisor/teams/team.slice';
import svNewsReducer from './components/features/supervisor/news/news.slice';
import { useDispatch } from 'react-redux';

const supervisorReducer = combineReducers({
  team: svTeamReducer,
  news: svNewsReducer,
});

const appReducer = combineReducers({
  messageModal: messageModalReducer,
  toast: toastReducer,
  auth: authReducer,
  thread: threadReducer,
  quickPrompt: quickPromptReducer,
  dlp: dlpReducer,
  membership: membershipReducer,
  membershipGroup: membershipGroupReducer,
  supervisor: supervisorReducer,
  news: newsReducer,
  report: reportReducer,
  file: fileReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: Action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
