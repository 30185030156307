import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { Box, Button, Divider, IconButton, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormHelperText, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from '@/lib/zod';
import { displayTimestamp } from '@/lib/utils';
import { captureException } from '@sentry/react';
import apiAxios from '@/lib/axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getCurrentMembership, LoginUser } from '../../auth/auth.type';
import { useToast } from '../../generic/hooks/useToast';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { setUserInfo } from '../../auth/auth.slice';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { firebaseAuth } from '@/lib/firebase';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { createPasswordSchema } from '@/lib/validation';
import i18next from 'i18next';
import { signInWithCustomToken } from "firebase/auth";

// 名前変更用のスキーマ
const nameSchema = z.object({
  name: z.string().min(1).max(100),
});

// メールアドレス変更用のスキーマ
const emailSchema = z.object({
  email: z.string().email(),
});

// パスワード変更用のスキーマ
const passwordSchema = z.object({
  currentPassword: z.string().min(1),
  newPassword: createPasswordSchema(),
  confirmPassword: z.string(),
}).superRefine((data, ctx) => {
  if (data.newPassword !== data.confirmPassword) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t: パスワードが一致しません
      message: i18next.t('auth:validationError.password.mismatch'),
      path: ['confirmPassword'],
    });
  }
});

type NameFormData = z.infer<typeof nameSchema>;
type EmailFormData = z.infer<typeof emailSchema>;
type PasswordFormData = z.infer<typeof passwordSchema>;

const ProfileSetting: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { setUnknownErrorMessageModal } = useMessageModal();
  const { setConfirmModal } = useConfirmModal();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const membership = getCurrentMembership(loginUser)

  // 名前変更フォーム
  const nameForm = useForm<NameFormData>({
    resolver: zodResolver(nameSchema),
    defaultValues: {
      name: loginUser?.name || '',
    },
  });

  // メールアドレス変更フォーム
  const emailForm = useForm<EmailFormData>({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: loginUser?.email || '',
    },
  });

  // パスワード変更フォーム
  const passwordForm = useForm<PasswordFormData>({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  // 名前変更処理
  const handleNameSubmit: SubmitHandler<NameFormData> = async (data) => {
    setIsLoading(true);
    try {
      await apiAxios.post('/auth/update-profile', data);
      const response = await apiAxios.get<LoginUser>('/auth/profile');
      dispatch(setUserInfo(response.data));
      // t:ユーザー名を更新しました。
      showToast(t("setting:profile.message.updateNameSuccess"), 'success');
      setIsNameDialogOpen(false);
    } catch (err) {
      captureException(err);
      setUnknownErrorMessageModal();
      console.error("update profile error", err);
    } finally {
      setIsLoading(false);
    }
  };

  // メールアドレス変更処理
  const handleEmailSubmit: SubmitHandler<EmailFormData> = async (data) => {
    setIsLoading(true);
    try {
      // メール検証が必要な旨を伝える確認ダイアログ
      setConfirmModal(
        t('setting:profile.dialog.emailConfirmTitle'),
        t('setting:profile.dialog.emailConfirmMessage'),
        async () => {
          try {
            // Email変更
            const res1 = await apiAxios.post('/auth/update-email', data);

            // 新しく返ってきたカスタムトークンでFirebaseを再認証
            // (こうしないとメールアドレスを変えたことでfirebaseAuth.currentUserがnullになってしまう)
            await signInWithCustomToken(firebaseAuth, res1.data.customToken);

            // 新しいプロファイルをセット
            const res2 = await apiAxios.get<LoginUser>('/auth/profile');
            dispatch(setUserInfo(res2.data));

            // t:メールアドレスを更新しました。
            showToast(t('setting:profile.message.updateEmailSuccess'), 'success');
            setIsEmailDialogOpen(false);
            navigate('/verify-email');
          } catch (err) {
            captureException(err);
            setUnknownErrorMessageModal();
            console.error("update email error", err);
          } finally {
            setIsLoading(false);
          }
        },
        () => {
          setIsLoading(false);
        }
      );
    } catch (err) {
      setIsLoading(false);
      captureException(err);
      setUnknownErrorMessageModal();
      console.error("update email error", err);
    }
  };

  // パスワード変更処理
  const handlePasswordSubmit: SubmitHandler<PasswordFormData> = async (data) => {
    setIsLoading(true);
    try {
      // 現在のユーザーを取得
      const currentUser = firebaseAuth.currentUser;
      console.log(firebaseAuth)
      if (!currentUser || !currentUser.email) {
        throw new Error('ユーザー情報が取得できません');
      }

      // 現在のパスワードで再認証
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        data.currentPassword
      );
      await reauthenticateWithCredential(currentUser, credential);

      // パスワード更新
      await updatePassword(currentUser, data.newPassword);

      // t:パスワードを更新しました。
      showToast(t('setting:profile.message.updatePasswordSuccess'), 'success');
      setIsPasswordDialogOpen(false);
      passwordForm.reset();
    } catch (err: any) {
      captureException(err);
      // Firebase認証エラーの処理
      if (err.code === 'auth/wrong-password') {
        passwordForm.setError('currentPassword', {
          type: 'manual',
          message: t('setting:profile.error.invalidCurrentPassword'),
        });
      } else {
        setUnknownErrorMessageModal();
      }
      console.error("update password error", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!loginUser) {
    return null;
  }

  return (
    <>
      {/* t:ユーザー情報 */}
      <AdminPageTitle title={t("setting:profile.pageTitle")} />
      <Divider />
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px' }}>
          {/* t:登録されているユーザー情報を確認できます。 */}
          {t("setting:profile.pageDescription")}
        </Typography>

        <Box mb={4} ml={1.5}>
          {
            membership &&
            <Box mb={3}>
              <Typography variant="caption" display="block" mb={1}>
                {/* t:メンバーID */}
                {t("setting:profile.column.memberId")}
              </Typography>
              <Typography variant="body1">{membership.id}</Typography>
            </Box>
          }

          <Box mb={3}>
            <Typography variant="caption" display="block" mb={1}>
              {/* t:氏名 */}
              {t("setting:profile.column.userName")}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">{loginUser.name}</Typography>
              {loginUser.hasPasswordAuth && (
                <IconButton size="small" onClick={() => setIsNameDialogOpen(true)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Box>

          <Box mb={3}>
            <Typography variant="caption" display="block" mb={1}>
              {/* t:メールアドレス */}
              {t("setting:profile.column.email")}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">{loginUser.email}</Typography>
              {!loginUser.hasGoogleAuth && (
                <IconButton size="small" onClick={() => setIsEmailDialogOpen(true)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Box>

          {
            loginUser.hasPasswordAuth &&
            <Box mb={3}>
              <Typography variant="caption" display="block" mb={1}>
                {/* t:パスワード */}
                {t("setting:profile.column.password")}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">••••••••</Typography>
                <IconButton size="small" onClick={() => setIsPasswordDialogOpen(true)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          }

          {
            membership &&
            <Box mb={3}>
              <Typography variant="caption" display="block" mb={1}>
                {/* t:登録日時 */}
                {t("setting:profile.column.createdAt")}
              </Typography>
              <Typography variant="body1">{displayTimestamp(membership.createdAt)}</Typography>
            </Box>
          }

        </Box>

        {/* 名前変更ダイアログ */}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={isNameDialogOpen}
          onClose={() => !isLoading && setIsNameDialogOpen(false)}>
          <DialogTitle>
            {/* t:氏名の変更 */}
            {t("setting:profile.dialog.nameTitle")}
          </DialogTitle>
          <form onSubmit={nameForm.handleSubmit(handleNameSubmit)}>
            <DialogContent>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  label={t("setting:profile.column.userName")}
                  {...nameForm.register('name')}
                  error={!!nameForm.formState.errors.name}
                  helperText={nameForm.formState.errors.name?.message}
                  disabled={isLoading}
                  fullWidth
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsNameDialogOpen(false)} disabled={isLoading}>
                {/* t:キャンセル */}
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {/* t:保存 */}
                {t("common:button.save")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {/* メールアドレス変更ダイアログ */}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={isEmailDialogOpen}
          onClose={() => !isLoading && setIsEmailDialogOpen(false)}
        >
          <DialogTitle>
            {/* t:メールアドレスの変更 */}
            {t("setting:profile.dialog.emailTitle")}
          </DialogTitle>
          <form onSubmit={emailForm.handleSubmit(handleEmailSubmit)}>
            <DialogContent>
              <FormControl fullWidth margin="dense">
                <TextField
                  autoFocus
                  label={t("setting:profile.column.email")}
                  {...emailForm.register('email')}
                  error={!!emailForm.formState.errors.email}
                  helperText={emailForm.formState.errors.email?.message}
                  disabled={isLoading}
                  fullWidth
                />
                <FormHelperText>
                  {/* t:新しいメールアドレスに変更すると、確認メールが送信されます。 */}
                  {t("setting:profile.dialog.emailHelperText")}
                </FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsEmailDialogOpen(false)} disabled={isLoading}>
                {/* t:キャンセル */}
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {/* t:保存 */}
                {t("common:button.save")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {/* パスワード変更ダイアログ */}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={isPasswordDialogOpen}
          onClose={() => !isLoading && setIsPasswordDialogOpen(false)}
        >
          <DialogTitle>
            {/* t:パスワードの変更 */}
            {t("setting:profile.dialog.passwordTitle")}
          </DialogTitle>
          <form onSubmit={passwordForm.handleSubmit(handlePasswordSubmit)}>
            <DialogContent>
              <FormControl fullWidth margin="dense">
                <TextField
                  label={t("setting:profile.dialog.currentPassword")}
                  type={showCurrentPassword ? 'text' : 'password'}
                  {...passwordForm.register('currentPassword')}
                  error={!!passwordForm.formState.errors.currentPassword}
                  helperText={passwordForm.formState.errors.currentPassword?.message}
                  disabled={isLoading}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                          edge="end"
                          tabIndex={-1}
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  label={t("setting:profile.dialog.newPassword")}
                  type={showNewPassword ? 'text' : 'password'}
                  {...passwordForm.register('newPassword')}
                  error={!!passwordForm.formState.errors.newPassword}
                  helperText={passwordForm.formState.errors.newPassword?.message}
                  disabled={isLoading}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                          tabIndex={-1}
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  label={t("setting:profile.dialog.confirmPassword")}
                  type={showConfirmPassword ? 'text' : 'password'}
                  {...passwordForm.register('confirmPassword')}
                  error={!!passwordForm.formState.errors.confirmPassword}
                  helperText={passwordForm.formState.errors.confirmPassword?.message}
                  disabled={isLoading}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                          tabIndex={-1}
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText>
                  {/* t:パスワードは8文字以上で、英大文字、英小文字、数字、記号(@$!%*?&-_)のうち2種類以上を含む必要があります。 */}
                  {t("common:message.passwordHelperText")}
                </FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsPasswordDialogOpen(false)} disabled={isLoading}>
                {/* t:キャンセル */}
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {/* t:保存 */}
                {t("common:button.save")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </AdminContentsWrapper>
    </>
  );
};

export default ProfileSetting;
