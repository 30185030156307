import { FC, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/store';
import { setRedirectUrl, setUserInfo } from '../auth.slice';
import useLoading from '../../generic/hooks/useLoading';
import apiAxios from '@/lib/axios';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../generic/hooks/useToast';

const AuthGuard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const { setIsLoading } = useLoading();
  const { t, i18n } = useTranslation();
  const { showToast } = useToast();

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      try {
        const response = await apiAxios.get('/auth/profile');
        console.debug('/auth/profile response', response)
        dispatch(setUserInfo(response.data));
        if (response.data.language) {
          i18n.changeLanguage(response.data.language);
        }
      } catch (error) {
        console.debug('/auth/profile error', error)

        const path = window.location.pathname;

        // ログイン後に現在のパスにリダイレクトするようにする
        dispatch(setRedirectUrl(path));

        // もしも /teams/:teamId/join/:inviteCode へのアクセスの場合は
        // 専用メッセージをToastで表示
        // t: まずは招待されたメールアドレスでログインを行ってください。
        if (path.match(/\/teams\/\w+\/join\/\w+/)) {
          showToast(t('auth:joinTeamWithInvitedEmail'), 'info');
        }

        // ログインページに遷移医を求める
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };

    if (!isAuthenticated) {
      checkAuth();
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, navigate, dispatch]);

  return isAuthenticated ? <Outlet /> : null;
};

export default AuthGuard;
