import { useMessageModal } from "@/components/features/generic/hooks/useMessageModal";

const PartsBox = () => {
  const { setMessageModal } = useMessageModal();

  const showErrorModal = () => {
    setMessageModal(
      'エラータイトル',
      'エラー内容\nエラー内容\nエラー内容'
    );
  };

  return <div style={{"paddingTop": 100}}>
    <button onClick={showErrorModal}>エラーモーダルテスト</button>
  </div>
};

export default PartsBox;
