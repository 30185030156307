import { useSelector } from "react-redux";
import { useMessageModal } from "../../generic/hooks/useMessageModal";
import { RootState } from "@/store";
import { getCurrentSubscription, hasAdminAuth } from "../../auth/auth.type";
import { PlanType } from "../../subscription/subscription.constant";
import { QuotaCheckResult } from "../../usage/usage.type";
import { PLAN_UPGRADE_URL } from "@/common/constants";
import { useTranslation } from "react-i18next";

export const useThreadAlertModal = () => {
  const { t } = useTranslation();
  const { setMessageModal } = useMessageModal();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const isUserAdmin = hasAdminAuth(loginUser);
  const currentSub = getCurrentSubscription(loginUser);
  const isStarter = currentSub?.plan.type === PlanType.STARTER

  const showQuotaExceededModal = (data: QuotaCheckResult) => {
    let title : string | undefined;
    let content : string | undefined;
    let buttonTitle : string | undefined;
    let buttonUrl : string | undefined;
    let onCloseCallback : (() => void) | undefined

    if (data.isMaxAiGenerateCountExceeded) {
      if (isStarter) {
        // t:スタータープランの利用可能上限に達しました
        title = t("thread:alert.starterPlanLimitReached.title");
        // t:大変恐縮ですが、スタータープランの利用可能上限に達しました。
        //   現在の利用回数：{{currentUsage}}
        //   利用可能上限：{{maxQuota}}
        //   この投稿で使われる回数：{{postedMentions}}
        //   {{exceedMessage}}
        //   プランをアップグレードすることで、より沢山を利用でき、
        //   また高度なAIも利用可能になります。ぜひご検討ください。
        content = t("thread:alert.starterPlanLimitReached.content", {
          currentUsage: data.usage.aiGenerateCount,
          maxQuota: data.quota.maxAiGenerateCount,
          postedMentions: data.postedMentionsCount,
          exceedMessage: (
            data.isMaxAiGenerateCountAlreadyExceeded ?
            // t:これ以上使うにはプランのアップグレードが必要となります。
            t("thread:alert.starterPlanLimitReached.alreadyExceeded") :
            // t:メンションの数を減らすことで投稿が可能になりますので、ご対応いただけますと幸いです。
            t("thread:alert.starterPlanLimitReached.canReduceMentions")
          )
        });
      } else {
        // t:投稿回数上限に達しました
        title = t("thread:alert.postLimitReached.title");
        // t:大変恐縮ですが、投稿回数上限に達しました。
        //   現在の利用回数：{{currentUsage}}
        //   利用可能上限：{{maxQuota}}
        //   この投稿で使われる回数：{{postedMentions}}
        //   ※ メンションの数だけカウントされます。
        //   {{exceedMessage}}
        content = t("thread:alert.postLimitReached.content", {
          currentUsage: data.usage.aiGenerateCount,
          maxQuota: data.quota.maxAiGenerateCount,
          postedMentions: data.postedMentionsCount,
          exceedMessage: data.isMaxAiGenerateCountAlreadyExceeded ?
            // t:これ以上お使いになるにはプランのアップグレードが必要となります。是非ご検討ください。
            t("thread:alert.postLimitReached.alreadyExceeded") :
            // t:メンションの数を減らすことで投稿が可能になりますので、ご対応いただけますと幸いです。
            t("thread:alert.postLimitReached.canReduceMentions")
        });
      }
      if (isUserAdmin) {
        // t:アップグレード申し込み
        buttonTitle = t("thread:alert.upgradeSubscription");
        buttonUrl = PLAN_UPGRADE_URL;
      }
    }

    if (data.isMaxInputCharsLengthExceeded) {
      if (isStarter) {
        // t:スタータープランの文字数制限について
        title = t("thread:alert.maxInputCharsLengthExceeded.starter.title");
        // t:大変恐縮ですが、スタータープランでは1回あたり{{maxChars}}文字まで入力可能となっております。
        //  (現在の合計入力文字数：{{currentChars}})
        //  プランをアップグレードすることで、より沢山を利用でき、また高度なAIも利用可能になります。ぜひご検討ください。
        content =  t("thread:alert.maxInputCharsLengthExceeded.starter.content", {
          maxChars: data.quota.maxInputCharsLength,
          currentChars: data.checkText?.length
        });
        if (isUserAdmin) {
          // t:アップグレード申し込み
          buttonTitle = t("thread:alert.upgradeSubscription");
          buttonUrl = PLAN_UPGRADE_URL;
        }
      } else {
        // t:文字数制限をオーバーしました
        title = t("thread:alert.maxInputCharsLengthExceeded.paid.title");
        // t:大変恐縮ですが、文字数上限をオーバーしました。
        //   文字数上限：{{maxChars}}
        //   入力文字数：{{currentChars}
        //  入力文字数を減らして再投稿いただけますよう何卒よろしくお願いいたします。
        content =  t("thread:alert.maxInputCharsLengthExceeded.paid.content", {
            maxChars: data.quota.maxInputCharsLength,
            currentChars: data.checkText?.length
          });
      }
    }

    if (data.isMaxInputCharsLengthExceededByMemberOver) {
      // t:現在、制限がかかっております
      title = t("thread:alert.maxInputCharsLengthExceededByMemberOver.title");
      // t:現在、メンバー数超過により{{maxChars}}文字制限がかかっております。
      content = t("thread:alert.maxInputCharsLengthExceededByMemberOver.content1", {
        maxChars: data.maxInputCharsLengthWhenOverMember
      });
      if (isUserAdmin) {
        // t:メンバー管理画面よりメンバーを減らすか、ライセンスの追加をご検討ください。
        content += t("thread:alert.maxInputCharsLengthExceededByMemberOver.content2.admin");
      } else {
        // t:詳しくは管理者にお問い合わせください。
        content += t("thread:alert.maxInputCharsLengthExceededByMemberOver.content2.normal");
      }
    }

    if (data.isCreditAlreadyExceeded) {
      if (data.isCreditAlreadyExceeded) {
        // t:チームの保有クレジット数をオーバーしました
        title = t("thread:alert.creditExceeded.alreadyExceeded.title");
        // t:大変恐縮ですが、保有クレジット数の上限に達しました。
        //   これ以上お使いになるにはプランのアップグレードが必要となります。是非ご検討ください。
        content = t("thread:alert.creditExceeded.alreadyExceeded.content");
      }
      if (data.isCreditWillExceeded) {
        // t:チームの保有クレジット数をオーバーします
        title = t("thread:alert.creditExceeded.willExceed.title");
        // t:この投稿をすると、保有クレジット数をオーバーしてしまいます。
        //   メンション数を減らす、文字数を減らす、新規スレッドではじめる等で投稿できる可能性がございます。
        //   またプランのアップグレードでより多くのクレジットを利用できるようになります。是非ご検討ください。
        content = t("thread:alert.creditExceeded.willExceed.content");
      }
      if (isUserAdmin) {
        // t:アップグレード申し込み
        buttonTitle = t("thread:alert.upgradeSubscription");
        buttonUrl = PLAN_UPGRADE_URL;
      }
    }

    if (data.isAiCodeUnusable) {
      // t:不適切なメンションです
      title = t("thread:alert.aiCodeUnusable.title");
      // t:現在のプランでは使えない生成AIモデルに対してメンションしています。
      //   画面をリロードして適切なモデルを選択してください。
      content = t("thread:alert.aiCodeUnusable.content");
    }

    if (!title || !content) {
      return
    }

    setMessageModal(
      title,
      content,
      (
        buttonTitle && buttonUrl ? { title: buttonTitle, url: buttonUrl } : undefined
      ),
      onCloseCallback,
    );
  };

  return {
    showQuotaExceededModal,
  };
};
