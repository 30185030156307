import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { PostMessageRequest, ThreadCreateType, UserMessage } from '../thread.type';
import { UserMessageType } from '../thread.constant';
import MessageFormContainer from '../containers/MessageFormContainer';
import QpFormContainer from '../../quickPrompt/containers/QpFormContainer';
import useThreadApi from '../hooks/useThreadApi';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { useThreadAlertModal } from '../hooks/useThreadAlertModal';
import useLoading from '../../generic/hooks/useLoading';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { extractMentionIdsFromTiptapData, hasMentionInTiptapData, isEmptyWithoutMention } from '@/common/utils/tiptap';
import { getMessageNode, getParentNode, handleDlpCheck } from '../thread.utils';
import { captureException } from '@sentry/react';
import { FileUpload } from '../../file/file.type';
import { checkUploadFile, checkUploadFileAndModel, FileUploadError, upload } from '../../file/file.utils';
import { getCurrentMembership, getCurrentSubscription } from '../../auth/auth.type';
import UploadProgressModal from '../../file/components/UploadProgressModal';
import { PlanType } from '../../subscription/subscription.constant';
import { useTranslation } from 'react-i18next';
import { getSelectAiModelsKey } from '../../quickPrompt/quickPrompt.utils';
import { DEFAULT_AI_MODEL_ID } from '../../aiModel/aiModel.constant';

interface UserMessageEditModalProps {
  open: boolean;
  onClose: () => void;
  userMsg: UserMessage;
}

const UserMessageEditModal: React.FC<UserMessageEditModalProps> = ({ open, onClose, userMsg }) => {
  const { t } = useTranslation();
  const { postMessage, stopGeneration } = useThreadApi();
  const { setMessageModal, setUnknownErrorMessageModal } = useMessageModal();
  const { setConfirmModal } = useConfirmModal();
  const {
    showQuotaExceededModal,
  } = useThreadAlertModal();
  const [ inputMessage, setInputMessage ] = useState<object | undefined>(undefined);
  const [ selectedFile, setSelectedFile ] = useState<File | FileUpload | undefined>(userMsg.fileUploads[0]);
  const [ fileUploadProgress, setFileUploadProgress ] = useState<string | undefined>(undefined);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)
  const currentSub = getCurrentSubscription(loginUser);
  const isStarter = !!(currentSub && currentSub.plan.type == PlanType.STARTER);

  const [ qpFormData, setQpFormData ] = useState<Record<string, any>>(
    userMsg.type == UserMessageType.NORMAL ? userMsg.body : {}
  );
  const { isLoading, setIsLoading } = useLoading();

  const threadOnGenerating = useSelector((state: RootState) => state.thread.threadOnGenerating);

  const currentThread = useSelector((state: RootState) => state.thread.currentThread);
  if (!currentThread) return null;

  // このユーザーメッセージも含めた全ての兄弟ノードを取得する
  const userMsgNode = getMessageNode(currentThread, userMsg);
  const parentNode = getParentNode(currentThread, userMsgNode);
  if (!(userMsgNode && parentNode)) return null;

  const inputMessageChanged = (newValue: object | undefined) => {
    setInputMessage(newValue);
  };

  const handleSendMessage = async () => {
    // ここでメッセージ送信の処理を実装
    console.debug('Sending message:', inputMessage);
    setIsLoading(true);

    // 該当ファイルをアップロードできるかモデルかチェック
    if (selectedFile) {
      let mentionIds: string[] = [];
      if (inputMessage) {
        mentionIds = extractMentionIdsFromTiptapData(inputMessage);
      }

      if (userMsg.quickPromptVersion) {
        const aiModelsKey = getSelectAiModelsKey(userMsg.quickPromptVersion)
        if (aiModelsKey && qpFormData && aiModelsKey in qpFormData) {
          mentionIds = qpFormData[aiModelsKey];
        } else {
          mentionIds = [DEFAULT_AI_MODEL_ID];
        }
      }

      const errorMsg = checkUploadFileAndModel(selectedFile, mentionIds, membership)
      if (errorMsg) {
        setIsLoading(false);
        // t:ファイルタイプエラー
        setMessageModal(t("thread:userMessageEdit.fileTypeError"), errorMsg);
        return;
      }
    }

    // ファイルが選択されている場合はアップロード
    let fileUpload: FileUpload | undefined;
    if (selectedFile) {
      if (selectedFile instanceof File) {
        try {
          fileUpload = await upload(selectedFile, setFileUploadProgress);
          setFileUploadProgress(undefined);
        } catch (err) {
          setFileUploadProgress(undefined);
          captureException(err);
          setIsLoading(false);
          console.error('File upload failed:', err);
          if (err instanceof FileUploadError) {
            // t:ファイルアップロードエラー
            setMessageModal(
              t("thread:userMessageEdit.fileUploadError.title"),
              err.message + (
                // t:ブラウザをリロードするか、しばらくしてから再度お試しください。
                err.retryable ? t("thread:userMessageEdit.fileUploadError.retryMessage") : ""
              )
            );
          } else {
            setUnknownErrorMessageModal();
          }
          return;
        }
      } else {
        fileUpload = selectedFile;
      }
    }

    const postMessageWrapper = (skipDlpBlock: boolean = false) => {
      let request : PostMessageRequest | undefined =  undefined

      // 通常のメッセージ
      if (userMsg.type == UserMessageType.NORMAL) {
        let _inputMessage = inputMessage;

        // 何も変更されていない場合はundefinedになるので、その場合は現在の値をセット
        if (_inputMessage == undefined) {
          _inputMessage = userMsg.body;
        }
        if (isEmptyWithoutMention(_inputMessage)) {
          setIsLoading(false);
          // t:メッセージが空です
          setMessageModal(
            t("thread:userMessageEdit.emptyMessage.title"),
            hasMentionInTiptapData(_inputMessage) ?
            // t:メンション以外のメッセージを入力してから送信してください。
            t("thread:userMessageEdit.emptyMessage.contentA") :
            // t:メッセージを入力してから送信してください。
            t("thread:userMessageEdit.emptyMessage.contentB")
          )
          return;
        }
        request = {
          type: ThreadCreateType.NORMAL,
          normalDetail: {
            message: _inputMessage,
          },
          skipDlpBlock: skipDlpBlock,
          parentNodeId: parentNode.id,
          fileUploadId: fileUpload?.id,
        }
      } else {
        request = {
          type: ThreadCreateType.QUICK_PROMPT,
          quickPromptDetail: {
            versionId: userMsg.quickPromptVersion?.id as string,
            formInput: Object.keys(qpFormData).length === 0 ? userMsg.body : qpFormData,
          },
          skipDlpBlock: skipDlpBlock,
          parentNodeId: parentNode.id,
          fileUploadId: fileUpload?.id,
        }
      }

      postMessage(
        currentThread.id,
        request,
        {
          onMessageNodesAdded: () => {
            setIsLoading(false);
          },
          onBlockedByDlp: (result) => handleDlpCheck(
            t, result, postMessageWrapper, setMessageModal, setConfirmModal
          ),
          onQuotaExceeded: (data) => showQuotaExceededModal(data.quotaCheckResult),
          onClose: () => {
            setIsLoading(false);
          },
        }
      );
    };

    postMessageWrapper();
  };

  const handleStopGeneration = () => {
      stopGeneration();
  }

  const formChangedCallback = (formData: object) : void => {
    setQpFormData(formData);
  }

  const onSelectFile = async (file: File | undefined) => {
    const errorMsg = await checkUploadFile(file, isStarter)
    if (errorMsg) {
      // t:ファイル添付エラー
      setMessageModal(t("thread:userMessageEdit.fileAttachmentError"), errorMsg);
      return;
    }
    setSelectedFile(file);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {/* t:メッセージ編集/クイックプロンプト編集 */}
      <DialogTitle>{userMsg.type === UserMessageType.NORMAL ? t('thread:userMessageEdit.title.normal') : t('thread:userMessageEdit.title.quickPrompt')}</DialogTitle>
      <DialogContent>
        {userMsg.type === UserMessageType.NORMAL && (
          <MessageFormContainer
            value={userMsg.body}
            onChange={inputMessageChanged}
            onSubmit={handleSendMessage}
            onStop={handleStopGeneration}
            onGenerating={threadOnGenerating}
            hideSubmitButton={true}
            disableGeneratingMessage={true}
            selectedFile={selectedFile}
            onSelectFile={onSelectFile}
          />
        )}
        {userMsg.type === UserMessageType.QUICK_PROMPT && (
          (
            userMsg.quickPromptVersion &&
            userMsg.quickPromptVersion.quickPrompt
          ) && <QpFormContainer
            quickPromptOrId={userMsg.quickPromptVersion.quickPrompt.id}
            versionId={userMsg.quickPromptVersion.id}
            input={userMsg.body as { [key: string]: unknown }}
            hideSendButton={true}
            initialFile={userMsg.fileUploads[0]}
            onSelectFile={onSelectFile}
            formChangedCallback={formChangedCallback}
          />
        )}
      </DialogContent>
      <DialogActions>
        {/* t:キャンセル */}
        <Button onClick={onClose} color="primary">{t('common:button.cancel')}</Button>
        {/* t:送信 */}
        <Button onClick={handleSendMessage}
          disabled={isLoading}
          color="primary">{t('common:button.send')}</Button>
      </DialogActions>

      <UploadProgressModal progress={fileUploadProgress} />
    </Dialog>
  );
};

export default UserMessageEditModal;
