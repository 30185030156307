import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import apiAxios from '@/lib/axios';
import { useTranslation } from 'react-i18next';
import useLoading from '../../generic/hooks/useLoading';
import { captureException } from '@sentry/react';
import PasswordResetPresenter from '../presenter/PasswordResetPresenter';
import { createPasswordSchema } from '@/lib/validation';
import i18next from 'i18next';

const resetSchema = z.object({
  newPassword: createPasswordSchema(),
  confirmPassword: z.string(),
}).superRefine((data, ctx) => {
  if (data.newPassword !== data.confirmPassword) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t: パスワードが一致しません
      message: i18next.t('auth:validationError.password.mismatch'),
      path: ['confirmPassword'],
    });
  }
});
type ResetFormData = z.infer<typeof resetSchema>;

interface PasswordResetContainerProps {
  resetCode?: string;
}

const PasswordResetContainer: React.FC<PasswordResetContainerProps> = ({ resetCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // resetCodeがundefinedの場合はエラーを表示
  React.useEffect(() => {
    if (!resetCode) {
      setError(t('auth:invalidResetCode'));
    }
  }, [resetCode, t]);

  const form = useForm<ResetFormData>({
    resolver: zodResolver(resetSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    }
  });

  const onSubmit = async (data: ResetFormData) => {
    if (!resetCode) {
      setError(t('auth:invalidResetCode'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await apiAxios.post('/auth/reset-password', {
        resetCode,
        newPassword: data.newPassword
      });

      setIsResetSuccess(true);
    } catch (error: any) {
      captureException(error);

      if (error.response?.data?.errorCode) {
        const errorCode = error.response.data.errorCode;
        switch (errorCode) {
          case 'reset_token_not_found':
            // t: 有効なリセットトークンが見つかりません
            setError(t('auth:resetTokenNotFound'));
            break;
          case 'reset_token_expired':
            // t: リセットトークンの有効期限が切れています
            setError(t('auth:resetTokenExpired'));
            break;
          case 'user_not_found':
            // t: ユーザーが見つかりません
            setError(t('auth:userNotFound'));
            break;
          default:
            // t: パスワードのリセットに失敗しました
            setError(t('auth:passwordResetFailed'));
        }
      } else {
        // t: パスワードのリセットに失敗しました
        setError(t('auth:passwordResetFailed'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoToLogin = () => {
    navigate('/login');
  };

  return (
    <PasswordResetPresenter
      form={form}
      onSubmit={onSubmit}
      isResetSuccess={isResetSuccess}
      error={error}
      onGoToLogin={handleGoToLogin}
    />
  );
};

export default PasswordResetContainer;
