import React from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
} from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import theme from "@/theme";
import { CenteredMainAreaWrapper, ContentsWrapper } from '@/components/ui/StyledComponents';

const VerificationPanel = styled(Paper)`
  padding: 40px;
  max-width: 600px;
  width: 100%;
  border-radius: 8px;
  border: none;
  margin: 0 auto;
  box-shadow: none;
`;

const VerificationTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
`;

const EmailHighlight = styled.span`
  font-weight: 600;
  word-break: break-all;
`;

const IconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: ${theme.palette.primary.main};
`;

const StepIcon = styled(ListItemIcon)`
  min-width: 36px;
`;

const ResendButton = styled(Button)`
  margin-top: 16px;
  height: 48px;
`;

interface EmailVerificationPresenterProps {
  email: string;
  resendCooldown: number;
  onResendVerification: () => void;
  error?: {
    code: string;
    message: string;
  } | null;
}

const EmailVerificationPresenter: React.FC<EmailVerificationPresenterProps> = ({
  email,
  resendCooldown,
  onResendVerification,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <ContentsWrapper>
      <CenteredMainAreaWrapper>
        <VerificationPanel elevation={0}>
          <IconWrapper>
            <MarkEmailReadIcon sx={{ fontSize: 64 }} />
          </IconWrapper>

          {/* t: メールアドレスの確認 */}
          <VerificationTitle variant="h5">
            {t('auth:verifyYourEmail')}
          </VerificationTitle>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error.message}
            </Alert>
          )}

          <Typography variant="body1" align="center" paragraph sx={{ mb: 3 }}>
            {/* t: 確認メールを送信しました: */}
            {t('auth:verificationEmailSentTo')} <EmailHighlight>{email}</EmailHighlight>
          </Typography>

          <Box sx={{ bgcolor: 'action.hover', p: 2, borderRadius: 2, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
              {/* t: 重要なお知らせ */}
              <Typography variant="subtitle1" fontWeight="medium">
                {t('auth:importantNote')}
              </Typography>
            </Box>
            <Typography variant="body2">
              {/* t: メールアドレスの確認が必要です。確認が完了するまでサービスの一部機能が制限される場合があります。 */}
              {t('auth:verificationRequiredWarning')}
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* t: 次のステップ */}
          <Typography variant="h6" gutterBottom>
            {t('auth:nextSteps')}:
          </Typography>

          <List>
            <ListItem disableGutters>
              <StepIcon>
                <EmailIcon color="primary" />
              </StepIcon>
              <ListItemText
                // t: メールを確認してください
                primary={t('auth:checkYourInbox')}
                // t: 迷惑メールフォルダも確認してください
                secondary={t('auth:checkSpamFolder')}
              />
            </ListItem>

            <ListItem disableGutters>
              <StepIcon>
                <CheckCircleOutlineIcon color="primary" />
              </StepIcon>
              <ListItemText
                // t: 確認リンクをクリックしてください
                primary={t('auth:clickVerificationLink')}
                // t: リンクの有効期限は24時間です
                secondary={t('auth:linkExpiresWarning')}
              />
            </ListItem>
          </List>

          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary" paragraph>
              {/* t: メールが届きませんでしたか？ */}
              {t('auth:didntReceiveEmail')}
            </Typography>

            <ResendButton
              variant="outlined"
              color="primary"
              onClick={onResendVerification}
              disabled={resendCooldown > 0}
              startIcon={<RefreshIcon />}
              size="large"
              fullWidth
            >
              {resendCooldown > 0
                ? `${t('auth:resendIn')} (${resendCooldown}s)`
                : t('auth:resendVerificationEmail')}
            </ResendButton>
          </Box>
        </VerificationPanel>
      </CenteredMainAreaWrapper>
    </ContentsWrapper>
  );
};

export default EmailVerificationPresenter;
