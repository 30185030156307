import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import useLoading from '@/components/features/generic/hooks/useLoading';
import { useMessageModal } from '@/components/features/generic/hooks/useMessageModal';
import { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';

const ShowFileContentPage = () => {
  const { isLoading, setIsLoading } = useLoading();
  const { setUnknownErrorMessageModal } = useMessageModal();
  const [file, setFile] = useState('');

  const handleClick = async () => {
    setIsLoading(true);
    try {
      // アクセストークンを取得
      const res1 = await apiAxios.get('/auth/access_token');
      const token = res1.data.accessToken;

      // ファイル内容をAPI側で取得
      await apiAxios.post('/debug/show-file-content', { token, file });
      setIsLoading(false);
    } catch (err) {
      setUnknownErrorMessageModal();
      captureException(err);
      setIsLoading(false);
      console.error(err);
    }
  };

  return (
    <Box sx={{ paddingTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <TextField
        label="ファイルID"
        variant="outlined"
        value={file}
        onChange={(e) => setFile(e.target.value)}
        fullWidth
        sx={{ maxWidth: 400 }}
      />
      <Button
        variant="contained"
        onClick={handleClick}
        disabled={isLoading || !file}
      >
        ファイル内容を表示
      </Button>
    </Box>
  );
};

export default ShowFileContentPage;
