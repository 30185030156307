import React, { useState } from 'react';
import { Plan } from '@/components/features/subscription/subscription.type';
import { ReqUpdateQuotaCustomization, ReqScheduleLicenseChange, ReqSchedulePlanChange, SvSubscriptionWithFull } from '../subscription.type';
import {
  Box,
  Button,
  Chip,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { SvTeamDetail } from '../../teams/team.type';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import SvSubscriptionPlanFormModal from '../components/SvSubscriptionPlanFormModal';
import SvSubscriptionLicenseFormModal from '../components/SvSubscriptionLicenseFormModal';
import SvQuotaCustomizationFormModal from '../components/SvQuotaCustomizationFormModal';
import { useTranslation } from 'react-i18next';
import { getPlanTypeLabel } from '@/components/features/subscription/subscription.constant';
import { getDaysDifference } from "@/lib/utils";

interface SvSubscriptionDetailPresenterProps {
  team: SvTeamDetail;
  plans: Plan[];
  subscriptions: SvSubscriptionWithFull[];
  onSchedulePlanChange: (data: ReqSchedulePlanChange) => void;
  onScheduleLicenseChange: (data: ReqScheduleLicenseChange) => void;
  onHandleQuotaCustomization: (subscriptionId: string, data: ReqUpdateQuotaCustomization) => void;
  onCancelPlanChange: () => void;
  onCancelLicenseChange: () => void;
}

const SvSubscriptionDetailPresenter: React.FC<SvSubscriptionDetailPresenterProps> = ({
  team,
  plans,
  subscriptions,
  onSchedulePlanChange,
  onScheduleLicenseChange,
  onHandleQuotaCustomization,
  onCancelPlanChange,
  onCancelLicenseChange,
}) => {
  const { t } = useTranslation();

  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);

  const currentSub = team.currentSubscription;
  const [selectedSubscription, setSelectedSubscription] = useState(currentSub);

  return <>
    {/* t:契約内容 */}
    <SupervisorPageTitle title={t("subscription:subscriptionDetail.title")} />
    <Divider />
    <SupervisorContentsWrapper>
      <Box sx={{ mb: 4 }}>
        {/*t:既に予約された変更があります。 */}
        <Tooltip title={team.scheduledSubscription ? t("subscription:subscriptionDetail.alreadyScheduledTooltip") : null}>
          <span>
            <Button
              variant="contained"
              onClick={() => setIsPlanModalOpen(true)}
              disabled={!!team.scheduledSubscription}
              sx={{ mr: 2}}
            >
              {/* t:プラン変更 */}
              {t("subscription:subscriptionChange.planChange")}
            </Button>
          </span>
        </Tooltip>

        {/* t:既に予約された変更があります。 */}
        <Tooltip title={currentSub.scheduledLicense ? t("subscription:subscriptionDetail.alreadyScheduledTooltip") : null}>
          <span>
            <Button
              variant="contained"
              onClick={() => setIsLicenseModalOpen(true)}
              disabled={!!currentSub.scheduledLicense}
            >
              {/* t:ライセンス数変更 */}
              {t("subscription:subscriptionChange.licenseChange")}
            </Button>
          </span>
        </Tooltip>

      </Box>

      <TableContainer>
        <Typography variant="h6" gutterBottom>
            {/* t:現在の内容 */}
            {t("subscription:subscriptionDetail.currentContent")}
        </Typography>
        <Table style={{ width: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {/* t:プラン */}
                {t("subscription:currentSubscription.plan")}
              </TableCell>
              <TableCell>
                {/* t:期間 */}
                {t("subscription:currentSubscription.currentPeriod")}
              </TableCell>
              <TableCell>
                {/* t:ライセンス数 */}
                {t("subscription:currentSubscription.licenseCount")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {getPlanTypeLabel(t, currentSub.plan.type)}
                {!!team.scheduledSubscription && (
                  <Typography variant="caption" color="error" component="span">
                    {/* t:(変更予約あり) */}
                    {t("subscription:subscriptionDetail.changeScheduledNote")}
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                {team.currentSubscription.latestContractTerm[0]} 〜 {team.currentSubscription.latestContractTerm[1]}
              </TableCell>
              <TableCell>
                {currentSub.currentLicense?.amount}
                {!!currentSub.scheduledLicense && (
                  <Typography variant="caption" color="error" component="span">
                    {/* t:(変更予約あり) */}
                    {t("subscription:subscriptionDetail.changeScheduledNote")}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h6" gutterBottom>
            {/* t:更新履歴 */}
            {t("subscription:subscriptionDetail.updateHistory")}
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {/* t:プラン */}
                  {t("subscription:subscriptionDetail.planName")}
                </TableCell>
                <TableCell>
                  {/* t:状態 */}
                  {t("subscription:subscriptionDetail.status")}
                </TableCell>
                <TableCell>
                  {/* t:適用開始日 */}
                  {t("subscription:subscriptionDetail.applyStartDate")}
                </TableCell>
                <TableCell>
                  {/* t:適用終了日 */}
                  {t("subscription:subscriptionDetail.applyEndDate")}
                </TableCell>
                <TableCell>
                  {/* t:契約基準日 */}
                  {t("subscription:subscriptionDetail.contractBaseDate")}
                </TableCell>
                <TableCell>
                  {/* t:最新の期間 */}
                  {t("subscription:subscriptionDetail.latestPeriod")}
                </TableCell>
                <TableCell>
                  {/* t:メモ */}
                  {t("subscription:subscriptionDetail.memo")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((sub) => (
                <React.Fragment key={"subscription-" + sub.id}>
                  <TableRow>
                    <TableCell>
                      {getPlanTypeLabel(t, sub.plan.type)}
                      <Link
                        style={{ marginLeft: '3px' }}
                        component="button"
                        variant="body2"
                        onClick={() => {
                          setSelectedSubscription(sub);
                          setIsQuotaModalOpen(true);
                        }}
                      >
                        {/* t:カスタム */}
                        {t("subscription:subscriptionDetail.customizeLink")}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Chip
                        sx={{ fontWeight: 'bold' }}
                        // t:現在/予約/終了
                        label={sub.isCurrent ? t('subscription:subscriptionDetail.statusLabels.current') : sub.isScheduled ? t('subscription:subscriptionDetail.statusLabels.scheduled') : t('subscription:subscriptionDetail.statusLabels.ended')}
                        color={sub.isCurrent ? 'primary' : sub.isScheduled ? 'success' : 'default'}
                      />
                      {sub.isScheduled &&
                        <Button
                          variant='text'
                          size="small"
                          color="error"
                          onClick={() => onCancelPlanChange()}
                        >
                          {/* t:取消 */}
                          {t("subscription:subscriptionDetail.cancelButton")}
                        </Button>
                      }
                    </TableCell>
                    <TableCell>{sub.applyStartedOn}{sub.isScheduled ? t('subscription:subscriptionDetail.applyAfterDaysNote', { applyAfterDays: getDaysDifference(sub.applyStartedOn) }) : ''}</TableCell>
                    <TableCell>{sub.applyEndedOn}</TableCell>
                    <TableCell>{sub.standardOn}</TableCell>
                    <TableCell>{sub.latestContractTerm[0]}〜{sub.latestContractTerm[1]}</TableCell>
                    <TableCell>{sub.memo}</TableCell>
                  </TableRow>

                  {sub.licenses.map((li) => (
                    <TableRow key={"license-" + li.id}>
                      <TableCell>
                          {/* t:ライセンス */}
                          &nbsp;&nbsp;&nbsp;└{li.amount}{t("subscription:subscriptionDetail.license")}
                          {
                            li.diff && <>
                              (
                                {li.diff > 0 ? "+" : ""}{li.diff}
                              )
                            </>
                          }
                      </TableCell>
                      <TableCell sx={{
                        color: li.isCurrent ? 'primary.main' : li.isScheduled ? 'success.main' : 'text.primary',
                        paddingLeft: 3.2
                      }}>
                        { sub.licenses.length > 1 && <>
                          {
                            // t:現在
                            li.isCurrent ? t('subscription:subscriptionDetail.statusLabels.current') :
                            // t:予約
                            li.isScheduled ? t('subscription:subscriptionDetail.statusLabels.scheduled') :
                            // t:終了
                            t('subscription:subscriptionDetail.statusLabels.ended')
                          }
                          {li.isScheduled &&
                            <Button
                              variant='text'
                              size="small"
                              color="error"
                              onClick={() => onCancelLicenseChange()}
                            >
                              {/* t:取消 */}
                              {t("subscription:subscriptionDetail.cancelButton")}
                            </Button>
                          }
                        </>}
                      </TableCell>
                      <TableCell>{
                        sub.licenses.length > 1 ? li.applyStartedOn : ""
                      }</TableCell>
                      <TableCell>{
                        sub.licenses.length > 1 ? li.applyEndedOn : ""
                      }</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>{li.memo}</TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </SupervisorContentsWrapper>
    <SvSubscriptionPlanFormModal
      open={isPlanModalOpen}
      plans={plans}
      onSubmit={onSchedulePlanChange}
      handleClose={() => setIsPlanModalOpen(false)}
    />
    <SvSubscriptionLicenseFormModal
      open={isLicenseModalOpen}
      onSubmit={onScheduleLicenseChange}
      handleClose={() => setIsLicenseModalOpen(false)}
    />
    <SvQuotaCustomizationFormModal
      open={isQuotaModalOpen}
      onSubmit={(data) => onHandleQuotaCustomization(selectedSubscription.id, data)}
      handleClose={() => setIsQuotaModalOpen(false)}
      subscription={selectedSubscription}
    />
  </>;
};

export default SvSubscriptionDetailPresenter;
