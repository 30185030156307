import { useParams } from 'react-router-dom';
import InfoPanel from '@/components/ui/InfoPanel';
import RegisterByInvite from '@/components/features/auth/components/RegisterByInvite';

const TeamsJoinRegisterPage = () => {
  const { teamId, inviteCode } = useParams<{ teamId: string; inviteCode: string }>();

  if (!teamId || !inviteCode) {
    return (
      <InfoPanel title="無効な招待URL">
        ご入力いただいたURLは無効な招待URLです。<br/>
        今一度、メールで送られたURLをご確認ください。
      </InfoPanel>
    );
  }

  return <RegisterByInvite teamId={teamId} inviteCode={inviteCode} />;
};

export default TeamsJoinRegisterPage;
