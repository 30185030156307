import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import apiAxios from '@/lib/axios';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { useTranslation } from 'react-i18next';
import useLoading from '../../generic/hooks/useLoading';
import EmailVerificationPresenter from '../presenter/EmailVerificationPresenter';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';

const EmailVerificationContainer: React.FC = () => {
  const { t } = useTranslation();
  const { setIsLoading } = useLoading();
  const { setMessageModal } = useMessageModal();
  const navigate = useNavigate();
  const [resendCooldown, setResendCooldown] = useState(0);
  const [error, setError] = useState<{ code: string; message: string } | null>(null);
  const user = useSelector((state: RootState) => state.auth.loginUser);

  // ユーザーがログインしていない、またはすでにメール検証済みの場合はリダイレクト
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    if (user.emailVerified) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleResendVerification = async () => {
    if (resendCooldown > 0) return;

    setError(null);
    setIsLoading(true);

    try {
      await apiAxios.post('/auth/resend-verification');

      // t: 確認メールを再送信しました
      // t: 確認メールを再送信しました。メールボックスを確認してください。
      setMessageModal(
        t('auth:verificationEmailResent'),
        t('auth:verificationEmailResentDescription')
      );

      // クールダウン設定（60秒）
      setResendCooldown(60);
      const timer = setInterval(() => {
        setResendCooldown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

    } catch (error: any) {
      captureException(error);

      let errorCode = 'unknown';
      let errorMessage = t('auth:verificationEmailErrorDescription');

      if (error.response?.data) {
        const responseData = error.response.data;
        errorCode = responseData.errorCode || 'unknown';

        // APIからのエラーコードに基づいてメッセージを設定
        switch (errorCode) {
          case 'already_verified':
            // t: メールアドレスは既に検証済みです
            errorMessage = t('auth:emailAlreadyVerified');
            // 検証済みの場合はホームにリダイレクト
            setTimeout(() => navigate('/'), 2000);
            break;
          case 'verification_not_found':
            // t: 検証情報が見つかりません
            errorMessage = t('auth:verificationNotFound');
            break;
          case 'verification_expired':
            // t: 検証コードの有効期限が切れています
            errorMessage = t('auth:verificationExpired');
            break;
          default:
            if (responseData.detail) {
              errorMessage = responseData.detail;
            }
            break;
        }
      }

      setError({ code: errorCode, message: errorMessage });

      // t: メール検証エラー
      // t: 確認メールの送信に失敗しました。しばらく時間をおいて再度お試しください。
      setMessageModal(
        t('auth:verificationEmailError'),
        errorMessage
      );
    } finally {
      setIsLoading(false);
    }
  };

  // ユーザーがログインしていない場合はローディング表示
  if (!user) {
    return null;
  }

  return (
    <EmailVerificationPresenter
      email={user.email || ''}
      resendCooldown={resendCooldown}
      onResendVerification={handleResendVerification}
      error={error}
    />
  );
};

export default EmailVerificationContainer;
