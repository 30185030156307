import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import styled from "@emotion/styled";
import { CenteredMainAreaWrapper, ContentsWrapper } from "@/components/ui/StyledComponents";
import theme from "@/theme";
import { useTranslation } from "react-i18next";
import SelectDisplayLanguage from "../../setting/containers/SelectDisplayLanguage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import GoogleIcon from "@mui/icons-material/Google";
import { Link as RouterLink } from "react-router-dom";

const LoginPanel = styled(Box)`
  justify-content: top;
  padding: 40px;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
    width: 100%;
    padding: 15px;
    border-radius: 0;
  }
`;

const LoginTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
`;

const LoginButton = styled(Button)`
  margin-top: 16px;
  height: 48px;
`;

const GoogleButton = styled(Button)`
  margin-top: 16px;
`;

const DividerWithText = styled(Box)`
  display: flex;
  align-items: center;
  margin: 32px 0 16px;

  &::before, &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  & > span {
    margin: 0 16px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }
`;

const LinkText = styled(RouterLink)`
  cursor: pointer;
  font-size: 14px;
  color: ${theme.palette.primary.main};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const AlertTypography = styled(
  ({ component, ...props }: { component: React.ElementType; [key: string]: any }) => (
    <Typography component={component} {...props} />
  )
)`
  &&& {
    color: ${theme.palette.warning.main};
  }
`;

interface LoginPresenterProps {
  // 既存のGoogleログイン用ハンドラ
  loginWithGoogle: () => void;
  // 追加: ID・パスワードログイン用ハンドラ
  loginWithIdPass: (userId: string, password: string) => void;
}

const LoginPresenter: React.FC<LoginPresenterProps> = ({ loginWithGoogle, loginWithIdPass }) => {
  const { t } = useTranslation();

  // ID・パスワードログイン用の入力State
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    loginWithIdPass(email, password);
  };

  return (
    <ContentsWrapper>
      <CenteredMainAreaWrapper>
        <LoginPanel>
          {/* t: ログイン */}
          <LoginTitle variant="h5">{t("auth:login")}</LoginTitle>

          <Box component="form" noValidate onSubmit={handleSubmit}>
            {/* t: メールアドレス */}
            <TextField
              label={t("auth:email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              required
              autoComplete="email"
              autoFocus
            />

            {/* t: パスワード */}
            <TextField
              label={t("auth:password")}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              required
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* t: ログイン */}
            <LoginButton
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              type="submit"
            >
              {t("auth:login")}
            </LoginButton>

            <Box display="flex" flexDirection="column" gap={1} mt={2}>
              {/* t: アカウントをお持ちではない方はこちら */}
              <LinkText to="/register">
                {t("auth:noAccountRegister")}
              </LinkText>

              {/* t: パスワードをお忘れですか？ */}
              <LinkText to="/password-reset">
                {t("auth:forgotPassword")}
              </LinkText>
            </Box>
          </Box>

          <DividerWithText>
            {/* t: または */}
            <span>{t("auth:or")}</span>
          </DividerWithText>

          {/* t: Googleで続行 */}
          <GoogleButton
            variant="contained"
            size="large"
            fullWidth
            onClick={loginWithGoogle}
            startIcon={<GoogleIcon />}
          >
            {t("auth:continueWithGoogle")}
          </GoogleButton>

          <Box mt={7} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {/* t: 表示言語 */}
            <Typography variant="caption">
              {t("auth:displayLanguage")}
            </Typography>
            <SelectDisplayLanguage slim />
          </Box>
        </LoginPanel>
      </CenteredMainAreaWrapper>
    </ContentsWrapper>
  );
};

export default LoginPresenter;
