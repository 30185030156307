import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import styled from "@emotion/styled";
import { useTranslation, Trans } from 'react-i18next';
import apiAxios from '@/lib/axios';
import { signInWithPopup, GoogleAuthProvider, signInWithCustomToken } from "firebase/auth";
import { firebaseAuth } from '@/lib/firebase';
import { CenteredMainAreaWrapper, ContentsWrapper } from '@/components/ui/StyledComponents';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// ※ 言語切替コンポーネント（LoginPresenter等を参考）
import SelectDisplayLanguage from '@/components/features/setting/containers/SelectDisplayLanguage';
import { z } from '@/lib/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import i18next from 'i18next';
import { createPasswordSchema, createTermsAgreementSchema } from '@/lib/validation';

type RegisterByInviteProps = {
  teamId: string;
  inviteCode: string;
};

type InviteInfo = {
  teamName: string;
  invitedEmail: string;
  isRegisteredUser: boolean;
};

const DividerWithText = styled(Box)`
  display: flex;
  align-items: center;
  margin: 10px 0 20px;

  &::before, &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  & > span {
    margin: 0 16px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }
`;

// 登録情報入力用のスキーマ
const registerSchema = z.object({
  name: z.string().min(1),
  password: createPasswordSchema(),
  confirmPassword: z.string(),
  agreed: createTermsAgreementSchema(),
}).superRefine((data, ctx) => {
  if (data.password !== data.confirmPassword) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t: パスワードが一致しません
      message: i18next.t('auth:validationError.password.mismatch'),
      path: ['confirmPassword'],
    });
  }
});

type RegisterFormData = z.infer<typeof registerSchema>;

const RegisterByInvite: React.FC<RegisterByInviteProps> = ({ teamId, inviteCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // 招待情報取得用のstate
  const [inviteInfo, setInviteInfo] = useState<InviteInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // 画面切替用のstate: 'screen1'は最初の招待案内画面、'screen2'はメールアドレス登録フォーム
  const [currentScreen, setCurrentScreen] = useState<'screen1' | 'screen2'>('screen1');

  // Email/Passによる新規登録フォーム（画面2用）
  // Email/Passによる新規登録フォーム
  const registerForm = useForm<RegisterFormData>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      name: '',
      password: '',
      confirmPassword: '',
      agreed: false,
    }
  });
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const teamJoinPagePath = `/teams/${teamId}/join/${inviteCode}`;

  useEffect(() => {
    const fetchInviteInfo = async () => {
      setLoading(true);
      try {

        // ログイン済みの場合はチーム参加ページに移動
        try {
          await apiAxios.get('/auth/profile');
          navigate(teamJoinPagePath);
        } catch (error) {
          // エラーになる=未ログインということなので何もしない
        }

        // 招待コードの情報を取得
        const response = await apiAxios.get(`/memberships/invite/${inviteCode}`);
        setInviteInfo(response.data);

      } catch (err: any) {
        const errorCode = err.response?.data?.errorCode;
        console.error('招待情報取得エラー:', errorCode, err);
        if (
          errorCode === 'invite_not_found' ||
          errorCode === 'membership_is_deleted'
        ) {
          // t: 無効な招待URLです
          setError(t('auth:registerByInvite:invalidInvite'));
        }
        else if (errorCode === 'invite_already_approved') {
          // t: 既に登録済みの招待URLです
          setError(t('auth:registerByInvite:alreadyRegistered'));
        }
        else if (errorCode === 'invite_is_expired') {
          // t: 招待URLの有効期限が切れています
          setError(t('auth:registerByInvite:expiredInvite'));
        }
        else {
          // t: 招待情報の取得に失敗しました
          setError(t('auth:registerByInvite:fetchError'));
        }
      } finally {
        setLoading(false);
      }
    };
    fetchInviteInfo();
  }, [inviteCode, t]);

  // Googleログインによる登録（または既存ユーザーの場合のログイン）
  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      // t: Googleログイン時に招待されたメールアドレスをヒントとして表示する
      if (inviteInfo?.invitedEmail) {
        provider.setCustomParameters({ login_hint: inviteInfo.invitedEmail });
      }
      const result = await signInWithPopup(firebaseAuth, provider);
      const idToken = await result.user.getIdToken();

      // ログイン時にinvite_codeを渡すことで、バックエンド側で招待登録処理を行う
      await apiAxios.post(
        '/auth/login',
        { idToken, lastLoginProvider: 'google.com' },
        { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
      );
      // ログイン後、チーム参加画面へリダイレクト
      navigate(`/teams/${teamId}/join/${inviteCode}`);
    } catch (error) {
      console.error('Googleログインエラー:', error);
      // t: Googleログインに失敗しました。しばらくしてから再度お試しください。
      setError(t('auth:loginFailedTryAgainLater'));
    }
  };

  // Email/Passによる新規登録
  const handleEmailRegister = async (data: RegisterFormData) => {
    if (submitting) return;

    setSubmitting(true);
    try {
      const response = await apiAxios.post('/auth/register', {
        email: inviteInfo?.invitedEmail,
        name: data.name,
        password: data.password,
        inviteCode,
        language: 'ja', // 固定またはユーザー選択に応じた値を設定
      });
      const customToken = response.data.customToken;
      await signInWithCustomToken(firebaseAuth, customToken);
      if (firebaseAuth.currentUser) {
        const idToken = await firebaseAuth.currentUser.getIdToken();
        await apiAxios.post(
          '/auth/login',
          { idToken, lastLoginProvider: 'password' },
          { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
        );
      }
      navigate('/teams/join/success');
    } catch (err: any) {
      console.error(err.response?.data?.detail);
      // エラーハンドリング（必要に応じてコメントアウト解除）
      // setError(err.response?.data?.detail || t('auth:registrationFailed', '登録に失敗しました'));
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <ContentsWrapper>
        <CenteredMainAreaWrapper>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        </CenteredMainAreaWrapper>
      </ContentsWrapper>
    );
  }

  if (error) {
    return (
      <ContentsWrapper>
        <CenteredMainAreaWrapper>
          <Alert severity="error">{error}</Alert>
        </CenteredMainAreaWrapper>
      </ContentsWrapper>
    );
  }

  if (!inviteInfo) {
    return (
      <ContentsWrapper>
        <CenteredMainAreaWrapper>
          {/* t: 無効な招待URLです */}
          <Alert severity="error">{t('auth:registerByInvite:invalidInvite')}</Alert>
        </CenteredMainAreaWrapper>
      </ContentsWrapper>
    );
  }

  // 既に登録済みの場合はログイン画面へ誘導
  if (inviteInfo.isRegisteredUser) {
    return (
      <ContentsWrapper>
        <CenteredMainAreaWrapper>
          <Box textAlign="center" mt={4}>
            {/* t: 既に登録済みです */}
            <Typography variant="h5">{t('auth:registerByInvite:alreadyRegisteredTitle')}</Typography>
            {/* t: 既にアカウントが作成されています。ログインしてチームに参加してください。 */}
            <Typography variant="body1" mt={2}>
              {t('auth:registerByInvite:alreadyRegisteredMessage')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(teamJoinPagePath)}
              sx={{ mt: 2 }}
            >
              {/* t: ログイン画面へ */}
              {t('auth:registerByInvite:goToLogin')}
            </Button>
          </Box>
        </CenteredMainAreaWrapper>
      </ContentsWrapper>
    );
  }

  // 画面1：招待案内＋作成方法選択
  const renderScreen1 = () => (
    <Box>
      {/* t: チーム「{{teamName}}」に招待されました。\nチームに参加するためのアカウントを作成してください。 */}
      <Typography variant="body1" style={{ whiteSpace: 'pre-line' }} gutterBottom>
        {t('auth:registerByInvite:screen1Description', { teamName: inviteInfo.teamName })}
      </Typography>

      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setCurrentScreen('screen2')}
          sx={{ mb: 2 }}
        >
          {/* t: メールアドレスで作成 */}
          {t('auth:registerByInvite:createWithEmailButton')}
        </Button>
      </Box>
    </Box>
  );

  // 画面2：メールアドレス登録フォーム
  const renderScreen2 = () => (
    <Box component="form" onSubmit={registerForm.handleSubmit(handleEmailRegister)} noValidate>
      {/* t: 次に氏名やパスワードなどの情報をご入力ください。 */}
      <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }} gutterBottom>
        {t('auth:registerByInvite:screen2Description')}
      </Typography>

      {/* Email（招待メールのアドレス：編集不可） */}
      <TextField
        /* t: メールアドレス */
        label={t('auth:registerByInvite:email')}
        value={inviteInfo.invitedEmail}
        fullWidth
        margin="normal"
        disabled
        InputProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
        }}
      />

      {/* t: 氏名 */}
      <TextField
        label={t('auth:registerByInvite:name')}
        fullWidth
        margin="normal"
        error={!!registerForm.formState.errors.name}
        helperText={registerForm.formState.errors.name?.message}
        {...registerForm.register('name')}
      />

      {/* t: パスワード */}
      <TextField
        label={t('auth:registerByInvite:password')}
        type={showPassword ? 'text' : 'password'}
        fullWidth
        margin="normal"
        error={!!registerForm.formState.errors.password}
        helperText={registerForm.formState.errors.password?.message}
        {...registerForm.register('password')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
                tabIndex={-1} // Prevent tab focus on the icon button
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* t: パスワード（確認） */}
      <TextField
        label={t('auth:registerByInvite:confirmPassword')}
        type={showConfirmPassword ? 'text' : 'password'}
        fullWidth
        margin="normal"
        error={!!registerForm.formState.errors.confirmPassword}
        helperText={registerForm.formState.errors.confirmPassword?.message}
        {...registerForm.register('confirmPassword')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                edge="end"
                tabIndex={-1} // Prevent tab focus on the icon button
              >
                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormHelperText>
        {/* t:パスワードは8文字以上で、英大文字、英小文字、数字、記号(@$!%*?&-_)のうち2種類以上を含む必要があります。 */}
        {t("common:message.passwordHelperText")}
      </FormHelperText>

      {/* 利用規約とプライバシーポリシー */}
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              {...registerForm.register('agreed')}
              color="primary"
            />
          }
          label={
            // t: <l1>利用規約</l1>と<l2>プライバシーポリシー</l2>に同意します
            <Trans
              i18nKey="auth:registerByInvite:agreeTerms"
              components={{
                l1: <a href={t('auth:urls:termPage')} target="_blank" rel="noopener" />,
                l2: <a href={t('auth:urls:privacyPolicyPage')} target="_blank" rel="noopener" />,
              }}
            />
          }
        />
        {registerForm.formState.errors.agreed && (
          <Typography color="error" variant="caption" sx={{ display: 'block', ml: 2 }}>
            {registerForm.formState.errors.agreed.message}
          </Typography>
        )}
      </Box>

      <Box mt={2.5} mb={3}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={registerForm.formState.isSubmitting || submitting}
        >
          {submitting
            /* t: 登録中… */
            ? t('auth:registerByInvite:registering')
            /* t: 登録 */
            : t('auth:registerByInvite:registerButton')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <ContentsWrapper>
      <CenteredMainAreaWrapper>
        <Box sx={{ maxWidth: 500, mx: 'auto', p: 2 }}>
          {/* t: アカウント作成 */}
          <Typography variant="h4" gutterBottom>
            {t('auth:registerByInvite:screen1Title')}
          </Typography>

          {currentScreen === 'screen1' ? renderScreen1() : renderScreen2()}

          <DividerWithText>
            {/* t: または */}
            <span>{t("auth:or")}</span>
          </DividerWithText>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleGoogleLogin}
          >
            {/* t: Googleアカウントで作成 */}
            {t('auth:registerByInvite:createWithGoogleButton')}
          </Button>
        </Box>

        <Box mt={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {/* t: 表示言語 */}
          <Typography variant="caption">
            {t("auth:displayLanguage")}
          </Typography>
          <SelectDisplayLanguage slim />
        </Box>
      </CenteredMainAreaWrapper>
    </ContentsWrapper>
  );
};

export default RegisterByInvite;
