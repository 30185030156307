import React, { useRef } from 'react';
import { Button } from '@mui/material';

interface FileUploadButtonProps {
  onFileSelect: (file: File) => void;
  currentFile?: File | undefined;
  text: string;
  disabled: boolean;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onFileSelect, currentFile, text, disabled }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const prevFileRef = useRef<File | undefined>(currentFile);

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileSelect(file);
    }
  };

  React.useEffect(() => {
    if (prevFileRef.current && !currentFile && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    prevFileRef.current = currentFile;
  }, [currentFile]);

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onFileChange}
        multiple={false} // ファイルは一つのみ選択できるようにする
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </Button>
    </>
  );
};

export default FileUploadButton;
