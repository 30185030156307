import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import apiAxios from '@/lib/axios';
import { useTranslation } from 'react-i18next';
import EmailVerificationCodePresenter from '../presenter/EmailVerificationCodePresenter';
import { setUserInfo } from '../auth.slice';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import useLoading from '../../generic/hooks/useLoading';
import { captureException } from '@sentry/react';
import { RootState } from '@/store';
import { getCurrentMembership } from '../auth.type';

type VerificationStatus = 'verifying' | 'success' | 'error';

const EmailVerificationCodeContainer: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const { t } = useTranslation();
  const { setIsLoading } = useLoading();
  const { setMessageModal } = useMessageModal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setStatus] = useState<VerificationStatus>('verifying');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)
  const canCreateTeam = !membership;

  useEffect(() => {
    const verifyEmail = async () => {
      if (!code) {
        setStatus('error');
        // t: 無効な認証コードです
        setErrorMessage(t('auth:invalidVerificationCode'));
        return;
      }

      setIsLoading(true);
      try {
        // バックエンドAPIを呼び出して認証コードを検証
        await apiAxios.get(`/auth/verify-email/${code}`);
        setStatus('success');

        // プロフィール情報を再取得して更新（emailVerifiedフラグを更新するため）
        try {
          const response = await apiAxios.get('/auth/profile');
          dispatch(setUserInfo(response.data));
        } catch (profileError) {
          captureException(profileError);
          console.error('プロフィール取得エラー:', profileError);
        }

      } catch (error: any) {
        captureException(error);
        console.error('メール認証エラー:', error);
        setStatus('error');

        // エラーレスポンスからメッセージを取得
        if (error.response?.data?.detail) {
          setErrorMessage(error.response.data.detail);
        } else if (error.response?.status === 404) {
          // t: 認証コードが見つかりません
          setErrorMessage(t('auth:verificationCodeNotFound'));
        } else if (error.response?.status === 400) {
          if (error.response.data?.errorCode === 'already_verified') {
            // t: メールアドレスは既に認証済みです
            setErrorMessage(t('auth:emailAlreadyVerified'));
          } else if (error.response.data?.errorCode === 'verification_expired') {
            // t: 認証コードの有効期限が切れています
            setErrorMessage(t('auth:verificationCodeExpired'));
          } else {
            // t: 無効な認証コードです
            setErrorMessage(t('auth:invalidVerificationCode'));
          }
        } else {
          // t: メール認証に失敗しました
          setErrorMessage(t('auth:verificationFailed'));
        }
      } finally {
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [code, dispatch, navigate, setMessageModal, setIsLoading, t]);

  const handleContinue = () => {
    if (canCreateTeam) {
      navigate('/teams/create?showForm=1');
    } else {
      navigate('/');
    }
  };

  const handleRetry = () => {
    navigate('/verify-email');
  };

  return (
    <EmailVerificationCodePresenter
      status={status}
      errorMessage={errorMessage}
      onContinue={handleContinue}
      onRetry={handleRetry}
      canCreateTeam={canCreateTeam}
    />
  );
};

export default EmailVerificationCodeContainer;
