import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { MembershipGroupList } from '../membership-group.type';
import apiAxios from '@/lib/axios';
import { appendMembershipGroups } from '../membership-group.slice';
import { captureException } from '@sentry/react';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import MembershipGroupListPresenter from '../presenters/MembershipGroupListPresenter';

const LIMIT = 20;

const MembershipGroupListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const membershipGroups = useSelector((state: RootState) => state.membershipGroup.membershipGroups);
  const membershipGroupNum = useSelector((state: RootState) => state.membershipGroup.num);
  const [hasMore, setHasMore] = useState(true);
  const { setUnknownErrorMessageModal } = useMessageModal();

  const handleScroll = async () => {
    try {
      const response = await apiAxios.get<MembershipGroupList>('/membership-groups', {
        params: {
          offset: membershipGroups.length,
          limit: LIMIT,
        },
      });
      dispatch(appendMembershipGroups(response.data));
      if (response.data.membershipGroups.length < LIMIT) {
        setHasMore(false);
      }
    } catch (error) {
      captureException(error);
      setUnknownErrorMessageModal();
      console.error('Failed to fetch more membershipGroups:', error);
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <MembershipGroupListPresenter
      membershipGroups={membershipGroups}
      membershipGroupNum={membershipGroupNum}
      onScroll={handleScroll}
      hasMore={hasMore}
    />
  );
};

export default MembershipGroupListContainer;
