import useDrivePicker from 'react-google-drive-picker';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import useLoading from '@/components/features/generic/hooks/useLoading';
import { useMessageModal } from '@/components/features/generic/hooks/useMessageModal';

const clientId : string = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const apiKey : string = import.meta.env.VITE_GOOGLE_API_KEY;


const PickerPage = () => {
  const [openPicker] = useDrivePicker();
  const { isLoading, setIsLoading } = useLoading();
  const { setUnknownErrorMessageModal } = useMessageModal();

  const handleOpenPicker = async () => {
    setIsLoading(true);
    try {
      const response = await apiAxios.get('/auth/access_token');
      const token = response.data.accessToken;
      setIsLoading(false);

      openPicker({
        clientId: clientId,
        developerKey: apiKey,
        viewId: "DOCS",
        token: token,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true,
        // customViews: customViewsArray, // custom view
        callbackFunction: (data) => {
          if (data.action === 'cancel') {
            console.debug('User clicked cancel/close button')
          }
        },
      });
    } catch (err) {
      setUnknownErrorMessageModal();
      captureException(err);
      console.error(err);
    }
  };

  return <div style={{"paddingTop": 100}}>
      <button onClick={handleOpenPicker} disabled={isLoading}>
        Open Picker
      </button>
  </div>
};

export default PickerPage;
