import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableCell,
  TableRow,
  Table,
  TableContainer,
  Paper,
  TableBody,
} from "@mui/material";
import useLoading from "../../generic/hooks/useLoading";
import { captureException } from "@sentry/react";
import UploadProgressModal from "../../file/components/UploadProgressModal";
import { useTranslation } from "react-i18next";

import apiAxios from '@/lib/axios';
import i18next from 'i18next';
import { MemberCsvValidateResult, MemberCsvValidateRowError, MemberCreateResult } from "../membership.type";
import { AxiosError } from 'axios';
import { MessageModalContent, showMessageModal, showUnknownErrorMessageModal } from '../../generic/messageModal.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentSubscription } from '../../auth/auth.type';
import { RootState } from '@/store';
import { PlanType } from '../../subscription/subscription.constant';
import { PLAN_UPGRADE_URL } from '@/common/constants';

interface CsvConfirmModalProps {
  open: boolean;
  onClose: () => void;
  currentFile?: File;
  rowCount: number | undefined;
  onCsvError: (errors: MemberCsvValidateRowError[]) => void;
}

const CsvConfirmModal: React.FC<CsvConfirmModalProps> = ({
  open,
  onClose,
  currentFile,
  rowCount,
  onCsvError,
}) => {
  const { t } = useTranslation();
  const [fileUploadProgress, setFileUploadProgress] = useState<
    string | undefined
  >(undefined);
  const { isLoading, setIsLoading } = useLoading();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const dispatch = useDispatch();

  const handleSendMessage = async () => {
    const currentSub = getCurrentSubscription(loginUser);
    const isStarter = currentSub && currentSub.plan.type == PlanType.STARTER;

    setIsLoading(true);

    // CSVをバリデーション、招待API呼び出しに必要な値を取得する
    if (currentFile) {
      if (currentFile instanceof File) {
        try {
          const formData = new FormData();
          formData.append('file', currentFile);

          const res = await apiAxios.post<MemberCsvValidateResult>('/memberships/csv-validate', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // t:ファイルをアップロードしています。(進行率: {{percentCompleted}}%)
                setFileUploadProgress(i18next.t('file:uploadingFile', { percentCompleted }));
              }
            },
          });

          setFileUploadProgress(undefined);

          if (res.data.rowErrors) {
            // 行ごとのエラー
            onCsvError(res.data.rowErrors);
          } else {
            // 成功したら招待APIを実行
            const formattedData = res.data.validatedRows;
            await apiAxios.post<MemberCreateResult>('/memberships', formattedData);

            // t:[CSVアップロード成功]\nCSVアップロードによる招待が正常に完了しました。\n画面をリロードします。
            window.alert(t("membership:invite.csv.success"));
            window.location.reload();
          }

        } catch (err) {
          setFileUploadProgress(undefined);
          captureException(err);
          setIsLoading(false);
          console.error("File upload failed:", err);

          if (err instanceof AxiosError) {

            const code = err.response?.data.errorCode;
            let modal: MessageModalContent | undefined = undefined;
            switch (code) {
              case "csv_format_error":
                modal = {
                  // t:CSVファイル形式エラー
                  title: i18next.t("membership:invite.csv.csvFormatError.title"),
                  // t:CSVファイルの形式が誤っています。CSVテンプレートをダウンロードしてファイルを作成してください。
                  message: i18next.t("membership:invite.csv.csvFormatError.message"),
                };
                break;

              case "license_limit_already_exceeded":
                if (isStarter) {
                  modal = {
                    // t:既にスターターのメンバー上限に達しています
                    title: i18next.t("membership:error.starterLimitReached.title"),
                    // t:スタータープランではメンバーは原則10名までとなっております。更にメンバーを追加したい場合はアップグレードをご検討ください。
                    message: i18next.t("membership:error.starterLimitReached.message"),
                    buttonOption: {
                      // t:アップグレードのお問い合わせ
                      title: i18next.t("membership:error.starterLimitReached.buttonTitle"),
                      url: PLAN_UPGRADE_URL,
                    },
                  };
                } else {
                  modal = {
                    // t:既にライセンス数の上限に達しています
                    title: i18next.t("membership:error.licenseLimitReached.title"),
                    // t:ライセンス数を超えるメンバーを招待することはできません。ライセンスの追加をご検討ください。
                    message: i18next.t("membership:error.licenseLimitReached.message"),
                    buttonOption: {
                      // t:ライセンス追加のお問い合わせ
                      title: i18next.t("membership:error.licenseLimitReached.buttonTitle"),
                      url: PLAN_UPGRADE_URL,
                    },
                  };
                }
                break;

              case "license_limit_will_exceeded":
                if (isStarter) {
                  modal = {
                    // t:スターターのメンバー上限です
                    title: i18next.t("membership:error.starterLimitWillExceed.title"),
                    // t:スタータープランではメンバーは原則10名までとなっております。更にメンバーを追加したい場合はアップグレードをご検討ください。
                    message: i18next.t("membership:error.starterLimitWillExceed.message"),
                    buttonOption: {
                      // t:アップグレードのお問い合わせ
                      title: i18next.t("membership:error.starterLimitWillExceed.buttonTitle"),
                      url: PLAN_UPGRADE_URL,
                    },
                  };
                } else {
                  modal = {
                    // t:ライセンスを超えたメンバー招待です
                    title: i18next.t("membership:error.licenseLimitWillExceed.title"),
                    // t:ライセンス数を超えるメンバーを招待することはできません。ライセンスの追加をご検討ください。
                    message: i18next.t("membership:error.licenseLimitWillExceed.message"),
                    buttonOption: {
                      // t:ライセンス追加のお問い合わせ
                      title: i18next.t("membership:error.licenseLimitWillExceed.buttonTitle"),
                      url: PLAN_UPGRADE_URL,
                    },
                  };
                }
                break;

              default:
                break;
            }
            if (modal) {
              dispatch(showMessageModal(modal));
            } else {
              dispatch(showUnknownErrorMessageModal());
            }
            onClose();
          }

          return;
        }
      }
    }

    setIsLoading(false);

  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {/* t:CSVアップロードの確認 */}
        {t("membership:invite.csv.confirmTitle")}
      </DialogTitle>

      <DialogContent>
        <DialogContentText style={{ whiteSpace: "pre-line" }}>
          {/* t:以下の内容で登録を行います。
          メンバー招待を進めるには、「アップロード開始」ボタンをクリックしてください。
          アップロード完了後、自動的にメンバーに招待メールが送信されます。 */}
          {t("membership:invite.csv.confirmMessage")}
        </DialogContentText>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {/* t:ファイル名 */}
                  {t("membership:invite.csv.fileName")}
                </TableCell>
                <TableCell>
                  {currentFile != null && currentFile instanceof File
                    ? currentFile.name
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {/* t:件数 */}
                  {t("membership:invite.csv.rowCount")}
                </TableCell>
                <TableCell>{rowCount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        {/* t:キャンセル */}
        <Button onClick={onClose}>
          {t("common:button.cancel")}
        </Button>
        {/* t:アップロード開始 */}
        <Button
          onClick={handleSendMessage}
          disabled={isLoading}
          variant="contained"
          color="primary"
        >
          {t("membership:invite.csv.upload")}
        </Button>
      </DialogActions>

      <UploadProgressModal progress={fileUploadProgress} />
    </Dialog>
  );
};

export default CsvConfirmModal;
