import React from 'react'
import ReactDOM from 'react-dom/client'
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // カスタムテーマのインポート
import Routing from './routing';
import LoadingIndicator from './components/features/generic/presenters/LoadingIndicator';
import { Provider as ReduxProvider } from 'react-redux'
import store from './store';
import { LoadingProvider } from './components/features/generic/contexts/LoadingContext';
import './lib/zod'; // zodのエラーメッセージ日本語化
import './lib/sentry'; // Sentryの有効化
import { ToastProvider } from './components/features/generic/contexts/ToastContext';
import { MessageModalProvider } from './components/features/generic/contexts/MessageModalContext';
import ContextMessageModalContainer from './components/features/generic/containers/ContextMessageModalContainer';
import ConfirmModalPresenter from './components/features/generic/presenters/ConfirmModalPresenter';
import { ConfirmModalProvider } from './components/features/generic/contexts/ConfirmModalContext';
import StateMessageModalContainer from './components/features/generic/containers/StateMessageModalContainer';
import StateToastContainer from './components/features/generic/contexts/StateToastContainer';
import ContextToastContainer from './components/features/generic/contexts/ContextToastContainer';
import { initializeI18n } from './lib/i18n/config'; // 多言語対応

const App: React.FC = () => {

  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
      {/* テーマ適用 */}
      <ThemeProvider theme={theme}>
      {/* ローディング表示のためのコンテキスト */}
      <LoadingProvider>
      {/* トースト表示のためのコンテキスト */}
      <ToastProvider>
      {/* エラーモダール用 */}
      <MessageModalProvider>
      {/* 確認モーダル用 */}
      <ConfirmModalProvider>

            {/* CSSベースラインを適用 */}
            <CssBaseline />

            {/* ルーティング */}
            <Routing />

            {/* 画面全体のローディング表示 */}
            <LoadingIndicator />

            {/* エラーモーダル表示 */}
            <ContextMessageModalContainer />
            <StateMessageModalContainer />

            {/* トースト表示 */}
            <StateToastContainer />
            <ContextToastContainer />

            {/* 確認モーダル表示 */}
            <ConfirmModalPresenter />

      </ConfirmModalProvider>
      </MessageModalProvider>
      </ToastProvider>
      </LoadingProvider>
      </ThemeProvider>
      </ReduxProvider>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

// 翻訳ファイルが読み込まれてからAPPをロード
(async () => {
  await initializeI18n();
  root.render(<App />);
})();
