import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import useLoading from '@/components/features/generic/hooks/useLoading';
import { useMessageModal } from '@/components/features/generic/hooks/useMessageModal';

const ListDriveFilesPage = () => {
  const { isLoading, setIsLoading } = useLoading();
  const { setUnknownErrorMessageModal } = useMessageModal();

  const handleClick = async () => {
    setIsLoading(true);
    try {
      // アクセストークンを取得
      const res1 = await apiAxios.get('/auth/access_token');
      const token = res1.data.accessToken;

      // ファイル一覧をAPI側で取得
      await apiAxios.post('/debug/list-drive-files', {token});
      setIsLoading(false);
    } catch (err) {
      setUnknownErrorMessageModal();
      captureException(err);
      console.error(err);
    }
  };

  return (
    <div style={{"paddingTop": 100}}>
      <button onClick={handleClick} disabled={isLoading}>
        Execute
      </button>
    </div>
  );
};

export default ListDriveFilesPage;
