import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type MessageModalContextProps = {
  isOpen: boolean;
  title: string;
  message: string;
  buttonOption?: {
    title: string;
    url: string;
  };
  setMessageModal: (title: string, message: string, buttonOption?: { title: string; url: string }, closeCallback?: () => void) => void;
  closeMessageModal: () => void;

  setUnknownErrorMessageModal: () => void;
};

export const MessageModalContext = createContext<MessageModalContextProps>({
  isOpen: false,
  title: '',
  message: '',
  setMessageModal: () => {},
  setUnknownErrorMessageModal: () => {},
  closeMessageModal: () => {},
});

export const MessageModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [buttonOption, setButtonOption] = useState<{ title: string; url: string }>();
  const [onCloseCallback, setOnCloseCallback] = useState<() => void>();
  const { t } = useTranslation();

  const setMessageModal = (title: string, message: string, buttonOption?: { title: string; url: string }, onCloseCallbackArg?: () => void) => {
    setTitle(title);
    setMessage(message);
    setButtonOption(buttonOption);
    setOnCloseCallback(() => onCloseCallbackArg);
    setIsOpen(true);
  };

  const closeMessageModal = () => {
    setIsOpen(false);
    if (onCloseCallback) {
      onCloseCallback();
    }
  };


  const setUnknownErrorMessageModal = () => {
    setMessageModal(
      t('common:message.unknownError'),
      t('common:message.unknownErrorFull'),
      // { title: t('お問い合わせ'), url: 'https://example.com/contact' }
    );
  }

  return (
    <MessageModalContext.Provider value={{ isOpen, title, message, buttonOption, setMessageModal, setUnknownErrorMessageModal, closeMessageModal }}>
      {children}
    </MessageModalContext.Provider>
  );
};
