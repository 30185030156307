import React from 'react';
import { useContext } from 'react';
import { MessageModalContext } from '../contexts/MessageModalContext';
import MessageModalPresenter from '../presenters/MessageModalPresenter';

const ContextMessageModalContainer: React.FC = () => {
  const { isOpen, title, message, buttonOption, closeMessageModal: closeErrorModal } = useContext(MessageModalContext);

  return (
    <MessageModalPresenter
      isOpen={isOpen}
      title={title}
      message={message}
      buttonOption={buttonOption}
      onClose={closeErrorModal}
    />
  );
};

export default ContextMessageModalContainer;
