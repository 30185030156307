import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvNewsListPresenter from '../presenters/SvNewsListPresenter';
import { RootState, AppDispatch } from '@/store';
import { News } from '../news.type';
import apiAxios from '@/lib/axios';
import { appendNews, setNews, deleteNews } from '../news.slice';
import { captureException } from '@sentry/react';
import { useMessageModal } from '@/components/features/generic/hooks/useMessageModal';
import { useTranslation } from 'react-i18next';

const LIMIT = 20;

const SvNewsListContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const newsList = useSelector((state: RootState) => state.supervisor.news.newsList);
  const [hasMore, setHasMore] = useState(true);

  const { setUnknownErrorMessageModal } = useMessageModal();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async (offset = 0) => {
    try {
      const response = await apiAxios.get<News[]>('/supervisor/news', {
        params: {
          limit: LIMIT,
          offset,
        },
      });
      if (offset === 0) {
        dispatch(setNews(response.data));
      } else {
        dispatch(appendNews(response.data));
      }
      setHasMore(response.data.length === LIMIT);
    } catch (err) {
      captureException(err);
      setUnknownErrorMessageModal();
    }
  };

  const handleScroll = () => {
    if (hasMore) {
      fetchNews(newsList.length);
    }
  };

  const handleDelete = async (news: News) => {
    try {
      const isConfirmed = window.confirm(
        // t:お知らせ「{{title}}」を削除してもよろしいですか？
        t('news:supervisor.list.deleteConfirm', { title: news.title })
      );

      if (isConfirmed) {
        await dispatch(deleteNews(news.id));
        fetchNews(0); // ニュースを削除した後、リストを最初から再取得
      }
    } catch (err) {
      captureException(err);
      setUnknownErrorMessageModal();
    }
  };

  return (
    <SvNewsListPresenter
      newsList={newsList}
      onScroll={handleScroll}
      onDelete={handleDelete}
      hasMore={hasMore}
    />
  );
};

export default SvNewsListContainer;
