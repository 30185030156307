import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { InitOptions } from "i18next";
import { zodI18nMap } from "zod-i18n-map";
// lib/zod.tsの方から参照させるために、ここではあえて名前を変えている
import { z as i18nZod, ZodIssueCode } from "zod";

// 動的に翻訳ファイルを読み込む関数
const loadTranslations = async (language: string) => {
  const translations: { [key: string]: any } = {};
  const files = import.meta.glob('./locales/*/*.json');
  for (const path in files) {
    if (path.includes(`/${language}/`)) {
      const module = await files[path]() as { default: unknown };
      const namespace = path.split('/').pop()?.replace('.json', '');
      if (namespace) {
        translations[namespace] = module.default;
      }
    }
  }
  return translations;
};

// カスタムErrorMap
const customErrorMap = (issue: any, ctx: any) => {
  // .min(1) のエラーの場合（too_smallエラーでminimumが1）
  if (issue.code === ZodIssueCode.too_small && issue.minimum === 1) {
    // 文字列の場合
    if (issue.type === "string") {
      return { message: i18n.t("zod:errors.required") };
    }
    // 配列の場合（必要に応じて）
    if (issue.type === "array") {
      return { message: i18n.t("zod:errors.select_at_least_one") };
    }
  }
  // 上記以外は zod-i18n-map のデフォルト処理をそのまま使用
  return zodI18nMap(issue, ctx);
};


// 言語リソースを動的に設定
export const initializeI18n = async () => {
  const resources = {
    en: await loadTranslations('en'),
    ja: await loadTranslations('ja'),
  };

  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      fallbackLng: 'en',
      returnEmptyString: false,
      resources: resources,
      ns: Object.keys(resources.ja), // 名前空間を動的に設定
      defaultNS: 'translation',
      interpolation: {
        // これにより、文字列内の HTML タグのエスケープが無効になります
        escapeValue: false
      },
      react: {
        // これにより、指定したタグがエスケープされずに保持されます
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span', "l"]
      },
      useSuspense: false,
    } as InitOptions);

  i18nZod.setErrorMap(customErrorMap);
};

initializeI18n();

export default i18n;
export { i18nZod }
