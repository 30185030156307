import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from '@/lib/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  TextField, Button, Typography, Alert, Link, Box,
  InputAdornment, IconButton, FormControlLabel, Checkbox,
  FormHelperText
} from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import apiAxios from '@/lib/axios';
import { signInWithCustomToken, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { firebaseAuth } from '@/lib/firebase';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CenteredMainAreaWrapper, ContentsWrapper } from '@/components/ui/StyledComponents';
import theme from '@/theme';
import SelectDisplayLanguage from '../../setting/containers/SelectDisplayLanguage';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../auth.slice';
import { captureException } from '@sentry/react';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { createPasswordSchema, createTermsAgreementSchema } from '@/lib/validation';

// スタイル定義
const RegisterPanel = styled(Box)`
  justify-content: top;
  padding: 40px;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
    width: 100%;
    padding: 15px;
    border-radius: 0;
  }
`;

const RegisterTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
`;

const RegisterButton = styled(Button)`
  margin-top: 16px;
  height: 48px;
`;

const GoogleButton = styled(Button)`
  margin-top: 16px;
`;

const DividerWithText = styled(Box)`
  display: flex;
  align-items: center;
  margin: 32px 0 16px;

  &::before, &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  & > span {
    margin: 0 16px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }
`;

const LinkText = styled(Link)`
  cursor: pointer;
  font-size: 14px;
  color: ${theme.palette.primary.main};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;


// メールアドレス入力用のスキーマ
const emailSchema = z.object({
  email: z.string().email(),
});

// 登録情報入力用のスキーマ
const registerSchema = z
  .object({
    name: z.string().min(1),
    email: z.string().email(),
    password: createPasswordSchema(),
    confirmPassword: z.string().min(8),
    language: z.enum(['en', 'ja']).optional().default('en'),
    agreed: createTermsAgreementSchema(),
  })
  .superRefine((data, ctx) => {
    // パスワード一致チェック
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        // t: パスワードが一致しません
        message: i18next.t('auth:validationError.password.mismatch'),
        path: ['confirmPassword'],
      });
    }
  });

type EmailFormData = z.infer<typeof emailSchema>;
type RegisterFormData = z.infer<typeof registerSchema>;

interface RegisterFormProps {
  hubSpotId: string | null;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ hubSpotId }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setMessageModal } = useMessageModal();
  const provider = new GoogleAuthProvider();

  const [step, setStep] = useState<1 | 2>(1);
  const [emailValue, setEmailValue] = useState('');
  const [serverError, setServerError] = useState<string | null>(null);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // メールアドレス入力フォーム
  const emailForm = useForm<EmailFormData>({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: '',
    }
  });

  // 登録情報入力フォーム
  const registerForm = useForm<RegisterFormData>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      agreed: false,
    }
  });

  // 利用規約とプライバシーポリシーのURL
  const termUrl = t("urls:termPage");
  const privacyPolicyUrl = t("urls:privacyPolicyPage");

  // メールアドレス入力後の処理
  const onEmailSubmit = (data: EmailFormData) => {
    setEmailValue(data.email);
    registerForm.setValue('email', data.email);
    setStep(2);
  };

  // メールアドレス編集に戻る
  const handleEditEmail = () => {
    emailForm.setValue('email', emailValue);
    setStep(1);
  };

  // パスワード表示切り替え
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // 確認用パスワード表示切り替え
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  /**
   * 共通処理: CIPから受け取ったidTokenを使ってバックエンドへログインし、
   * ユーザープロフィールを取得してReduxに保存する。
   */
  const doAuthLogin = async (cipIdToken: string) => {
    try {
      // バックエンドへIDトークン送信（セッションクッキー発行）
      await apiAxios.post(
        '/auth/login',
        {
          idToken: cipIdToken,
          lastLoginProvider: 'google.com',
          language: t('common:language'),
          hubSpotId,
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      // ユーザープロフィール取得
      const profileResponse = await apiAxios.get('/auth/profile', { withCredentials: true });
      dispatch(setUserInfo(profileResponse.data));

      // ログイン後のリダイレクト
      navigate('/');
    } catch (error) {
      captureException(error);
      // t: ログインエラー
      const errorTitle = t("auth:loginError");
      // t: ログインに失敗しました。しばらく時間をおいて再度お試しください。
      const errorMessage = t("auth:loginFailedTryAgainLater");
      setMessageModal(errorTitle, errorMessage);
    }
  };

  // Googleでの登録処理
  const handleGoogleRegister = async () => {
    try {
      // GoogleログインをFirebase Authで実行（ポップアップ方式）
      const result = await signInWithPopup(firebaseAuth, provider);
      const idToken = await result.user.getIdToken();

      // 共通処理: CIPで取得したトークンを使ってバックエンドへログイン
      await doAuthLogin(idToken);
    } catch (error: any) {
      captureException(error);

      // t: 登録エラー
      let errorTitle = t("auth:registrationError");
      // t: 登録に失敗しました。しばらく時間をおいて再度お試しください。
      let errorMessage = t("auth:registrationFailedTryAgainLater");

      if (error?.code) {
        switch (error.code) {
          case 'auth/account-exists-with-different-credential':
            // t: このメールアドレスは既に別の認証方法で登録されています
            errorMessage = t("auth:accountExistsWithDifferentCredential");
            break;
          case 'auth/popup-closed-by-user':
            // t: ログインポップアップが閉じられました。再度お試しください。
            errorMessage = t("auth:popupClosedByUser");
            break;
          case 'auth/cancelled-popup-request':
            // t: ポップアップリクエストがキャンセルされました。
            errorMessage = t("auth:cancelledPopupRequest");
            break;
          case 'auth/popup-blocked':
            // t: ポップアップがブラウザによってブロックされました。ポップアップを許可してください。
            errorMessage = t("auth:popupBlocked");
            break;
          default:
            // デフォルトのエラーメッセージを使用
            break;
        }
      }

      setMessageModal(errorTitle, errorMessage);
    }
  };

  // メールアドレスとパスワードでの登録処理
  const onRegisterSubmit = async (data: RegisterFormData) => {
    setServerError(null);
    setIsAlreadyRegistered(false);

    try {
      // ① ユーザー登録APIを呼び出す
      const response: any = await apiAxios.post('/auth/register', {
        email: data.email,
        name: data.name,
        password: data.password,
        language: i18n.language,
        hubSpotId,
      });

      const customToken = response.data.customToken as string;

      // ② カスタムトークンを用いて自動ログイン
      await signInWithCustomToken(firebaseAuth, customToken);

      // ③ ログイン後、IDトークンを取得してセッションクッキー発行APIを呼ぶ
      if (firebaseAuth.currentUser) {
        const idToken = await firebaseAuth.currentUser.getIdToken();
        await apiAxios.post(
          '/auth/login',
          { idToken, lastLoginProvider: 'password' },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );
      }

      // ユーザープロフィール取得
      const profileResponse = await apiAxios.get('/auth/profile', { withCredentials: true });
      dispatch(setUserInfo(profileResponse.data));

      // 登録完了後、メールアドレス検証へ遷移
      navigate('/verify-email');
    } catch (error: any) {
      if (error.response?.data?.errorCode === 'already_exists') {
        setIsAlreadyRegistered(true);
        // t: このメールアドレスは既に登録されています。ログインページからログインしてください。
        setServerError(t('auth:emailAlreadyRegistered'));
      } else {
        setServerError(t('common:message.unknownErrorFull'));
      }
    }
  };

  return (
    <ContentsWrapper>
      <CenteredMainAreaWrapper>
        <RegisterPanel>
          {/* ステップ1: メールアドレス入力 */}
          {step === 1 && (
            <>
              {/* t: アカウント作成 */}
              <RegisterTitle variant="h5">{t("auth:createAccount")}</RegisterTitle>

              <Typography variant="body2" paragraph>
                {/* t: はじめにメールアドレスをご入力ください。 */}
                {t("auth:enterEmailFirst")} <br/>
                {/* t: 仕事でお使いのメールアドレスがおすすめです。 */}
                {t("auth:workEmailRecommended")}
              </Typography>

              <Box component="form" onSubmit={emailForm.handleSubmit(onEmailSubmit)} noValidate>
                {/* t: メールアドレス */}
                <TextField
                  label={t("auth:email")}
                  fullWidth
                  margin="normal"
                  required
                  autoComplete="email"
                  autoFocus
                  error={!!emailForm.formState.errors.email}
                  helperText={emailForm.formState.errors.email?.message}
                  {...emailForm.register('email')}
                />

                {/* t: 続ける */}
                <RegisterButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={emailForm.formState.isSubmitting}
                >
                  {t("auth:continue")}
                </RegisterButton>
              </Box>

              <Box display="flex" flexDirection="column" alignItems="left" mt={3}>
                <Typography variant="body2">
                  {/* t: アカウントをお持ちですか？ */}
                  {t("auth:alreadyHaveAccount")} &nbsp;
                  <RouterLink to="/login">
                    <LinkText>
                      {/* t: ログイン */}
                      {t("auth:loginHere")}
                    </LinkText>
                  </RouterLink>
                </Typography>
              </Box>

              <DividerWithText>
                {/* t: または */}
                <span>{t("auth:or")}</span>
              </DividerWithText>

              <Typography variant="body2" align="left" paragraph sx={{ mt: 4, mb:2}}>
                {/* t: 他のサービスのアカウントで作成したい場合は、 */}
                {t("auth:createWithOtherService")} <br/>
                {/* t: 下記のボタンから各サービスでログインしてください。 */}
                {t("auth:loginWithServiceBelow")}
              </Typography>

              {/* t: Googleで続行 */}
              <GoogleButton
                variant="contained"
                size="large"
                fullWidth
                onClick={handleGoogleRegister}
                startIcon={<GoogleIcon />}
              >
                {t("auth:continueWithGoogle")}
              </GoogleButton>

              <Box mt={7} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                {/* t: 表示言語 */}
                <Typography variant="caption">
                  {t("auth:displayLanguage")}
                </Typography>
                <SelectDisplayLanguage slim />
              </Box>
            </>
          )}

          {/* ステップ2: アカウント情報入力 */}
          {step === 2 && (
            <>
              {/* t: アカウント作成 */}
              <RegisterTitle variant="h5">{t("auth:createAccount")}</RegisterTitle>

              <Typography variant="body1" align="center" paragraph sx={{ mb: 3 }}>
                {/* t: 次に氏名やパスワードなどの情報を入力してください。 */}
                {t("auth:enterNameAndPassword")}
              </Typography>

              {serverError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {serverError}
                  {isAlreadyRegistered && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <Link component={RouterLink} to="/login">
                        {/* t: ログインページへ移動する */}
                        {t("auth:goToLoginPage")}
                      </Link>
                    </Typography>
                  )}
                </Alert>
              )}

              <Box component="form" onSubmit={registerForm.handleSubmit(onRegisterSubmit)} noValidate>
                {/* メールアドレス（編集不可） */}
                <TextField
                  label={t("auth:email")}
                  value={emailValue}
                  fullWidth
                  margin="normal"
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          onClick={handleEditEmail}
                          sx={{ minWidth: 'auto', p: '4px 8px' }}
                        >
                          {/* t: 編集 */}
                          {t("common:button.edit")}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiInputBase-root.Mui-disabled': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    }
                  }}
                />

                {/* t: 氏名 */}
                <TextField
                  label={t("auth:name")}
                  fullWidth
                  margin="normal"
                  required
                  autoFocus
                  error={!!registerForm.formState.errors.name}
                  helperText={registerForm.formState.errors.name?.message}
                  {...registerForm.register('name')}
                />

                {/* t: パスワード */}
                <TextField
                  label={t("auth:password")}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  margin="normal"
                  required
                  error={!!registerForm.formState.errors.password}
                  helperText={registerForm.formState.errors.password?.message}
                  {...registerForm.register('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          tabIndex={-1} // Prevent tab focus on the icon button
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {/* t: パスワード（確認） */}
                <TextField
                  label={t("auth:confirmPassword")}
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  margin="normal"
                  required
                  error={!!registerForm.formState.errors.confirmPassword}
                  helperText={registerForm.formState.errors.confirmPassword?.message}
                  {...registerForm.register('confirmPassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleToggleConfirmPasswordVisibility}
                          edge="end"
                          tabIndex={-1} // Prevent tab focus on the icon button
                        >
                          {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <FormHelperText>
                  {/* t:パスワードは8文字以上で、英大文字、英小文字、数字、記号(@$!%*?&-_)のうち2種類以上を含む必要があります。 */}
                  {t("common:message.passwordHelperText")}
                </FormHelperText>

                {/* 利用規約とプライバシーポリシーの同意 */}
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...registerForm.register('agreed')}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {/*
                          t: <l1>利用規約</l1>と
                          <l2>プライバシーポリシー</l2>
                          に同意します
                        */}
                        <Trans i18nKey="team:create.agreeTerms"
                          components={{
                            l1: <Link href={termUrl} target="_blank" color="primary"/>,
                            l2: <Link href={privacyPolicyUrl} target="_blank" color="primary"/>
                          }}
                        />
                      </Typography>
                    }
                  />
                  {registerForm.formState.errors.agreed && (
                    <Typography color="error" variant="caption" sx={{ display: 'block', ml: 2 }}>
                      {registerForm.formState.errors.agreed.message}
                    </Typography>
                  )}
                </Box>

                {/* t: アカウントを作成する */}
                <RegisterButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={registerForm.formState.isSubmitting}
                >
                  {t("auth:createAccount")}
                </RegisterButton>
              </Box>
            </>
          )}
        </RegisterPanel>
      </CenteredMainAreaWrapper>
    </ContentsWrapper>
  );
};

export default RegisterForm;
