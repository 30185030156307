import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import apiAxios from '@/lib/axios';
import { useTranslation } from 'react-i18next';
import useLoading from '../../generic/hooks/useLoading';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { captureException } from '@sentry/react';
import PasswordReminderPresenter from '../presenter/PasswordReminderPresenter';

// メールアドレス入力用のスキーマ
const reminderSchema = z.object({
  email: z.string().email(),
});

type ReminderFormData = z.infer<typeof reminderSchema>;

const PasswordReminderContainer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { setMessageModal } = useMessageModal();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const form = useForm<ReminderFormData>({
    resolver: zodResolver(reminderSchema),
    defaultValues: {
      email: '',
    }
  });

  const onSubmit = async (data: ReminderFormData) => {
    setIsLoading(true);
    try {
      await apiAxios.post('/auth/password-reminder', {
        email: data.email
      });

      setIsSubmitted(true);
    } catch (error) {
      captureException(error);
      // t: パスワードリセットエラー
      // t: パスワードリセットメールの送信に失敗しました。しばらく時間をおいて再度お試しください。
      setMessageModal(
        t('auth:passwordResetError'),
        t('auth:passwordResetEmailFailedTryAgainLater')
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  return (
    <PasswordReminderPresenter
      form={form}
      onSubmit={onSubmit}
      isSubmitted={isSubmitted}
      onBackToLogin={handleBackToLogin}
    />
  );
};

export default PasswordReminderContainer;
