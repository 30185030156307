import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState } from 'react';
import { MembershipManaged, MembershipManagedList, MemberCsvValidateRowError } from '../membership.type';
import {
  CircularProgress,
  Button,
  IconButton,
  Divider,
  useMediaQuery,
  useTheme,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import MembershipFormModal from '../components/MembershipFormModal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMembership, reinviteMembership } from '../membership.slice';
import { AppDispatch, RootState } from '@/store';
import { getCurrentMembership, getCurrentSubscription, hasAdminAuth, hasSuperAdminAuth } from '@/components/features/auth/auth.type';
import { MembershipRole, getMembershipRoleLabel } from '../membership.constant';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { ResponsiveTableActionFooter, ResponsiveTableCell, ResponsiveTableRow, ResponsiveTableRowHeader } from '@/components/ui/ResponsiveComponents';
import { displayTimestamp } from '@/lib/utils';
import { PlanType } from '../../subscription/subscription.constant';
import { MembershipGroup } from '../../membership-group/membership-group.type';
import { useTranslation } from 'react-i18next';
import FileUploadButton from '../components/FileUploadButton';
import CsvConfirmModal from '../components/CsvConfirmModal';
import CsvErrorModal from '../components/CsvErrorModal';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { checkUploadFileCSV, getCsvRowCount } from '../../file/file.utils';
import DownloadIcon from '@mui/icons-material/Download';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { downloadCsvFromData } from '@/lib/csv';
import { formatTimestamp } from '@/lib/utils';
interface MembershipListPresenterProps {
  memberships: MembershipManaged[];
  membershipNum: number;
  membershipGroups: MembershipGroup[];
  onScroll: () => void;
  selectedGroupId: string;
  onChangeSelectedGroupId: (groupId: string) => void;
  hasMore: boolean;
}

const MembershipListPresenter: React.FC<MembershipListPresenterProps> = ({
  memberships,
  membershipGroups,
  onScroll,
  selectedGroupId,
  onChangeSelectedGroupId,
  hasMore,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState<MembershipManaged | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const team = getCurrentMembership(loginUser)?.team;
  const currentSub = getCurrentSubscription(loginUser);
  if (!(loginUser && team && currentSub)) {
    return null;
  }
  const totalMembersCount = team.membersCount;
  const licenseAmount = currentSub.currentLicense.amount;
  const isLicenseLimitReached = totalMembersCount >= licenseAmount;
  const isLicenseLimitOver = totalMembersCount > licenseAmount;

  const { setMessageModal } = useMessageModal();
  const { setUnknownErrorMessageModal } = useMessageModal();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const [selectedFileRowCount, setSelectedFileRowCount] = useState<number | undefined>(undefined);
  const [isCsvConfirmModalOpen, setIsCsvConfirmModalOpen] = useState(false);
  const [isCsvErrorModalOpen, setIsCsvErrorModalOpen] = useState(false);
  const [csvErrors, setCsvErrors] = useState<MemberCsvValidateRowError[]>([]);

  const handleAddClick = () => {
    setSelectedMembership(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (membership: MembershipManaged) => {
    setSelectedMembership(membership);
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (id: string) => {
    // t:本当に削除しますか？
    if (window.confirm(t('membership:delete.confirm'))) {
      await dispatch(deleteMembership(id));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleReinvite = async (membership: MembershipManaged) => {
    await dispatch(reinviteMembership({
      "id": membership.id,
    }));
  }

  const handleTemplateDownload = async () =>  {
    const csvHeader = [
      t("membership:invite.csv.header.email"),
      t("membership:invite.csv.header.role"),
      t("membership:invite.csv.header.group"),
    ];
    const csvData: string[][] = [];

    await downloadCsvFromData(csvHeader, csvData, 'member_list_template.csv');

  };

  const handleMemberListDownload = async () => {
    try {
      const response = await apiAxios.get<MembershipManagedList>('/memberships');
      const groupMemberships = response.data.memberships

      const csvHeader = [
        // t:メールアドレス
        t("membership:table.header.email"),
        // t:ロール
        t("membership:table.header.role"),
        // t:所属グループ
        t("membership:table.header.group"),
        // t:ステータス
        t("membership:table.header.status"),
        // t:作成日時
        t("common:info.createdAt"),
        // t:更新日時
        t("common:info.updatedAt")
      ];
      const csvData: string[][] = groupMemberships.map((membership) => (
        [
          membership.user.email,
          getMembershipRoleLabel(t, membership.role),
          membership.group?.name || "",
          membership.isJoined ? t('membership:status.joined') : t('membership:status.invited'),
          displayTimestamp(membership.createdAt),
          displayTimestamp(membership.updatedAt),
        ]
      ));

      const timestamp = formatTimestamp(new Date());
      await downloadCsvFromData(csvHeader, csvData, `member_list_${timestamp}.csv`);
    } catch (error) {
      captureException(error);
      setUnknownErrorMessageModal();
      console.error('Failed to fetch group memberships:', error);
    }
  };


  const handleCsvError = (errors: MemberCsvValidateRowError[]) => {
    setIsCsvConfirmModalOpen(false);
    setCsvErrors(errors);
    setIsCsvErrorModalOpen(true);
  };

  const onSelectFile = async (file: File | undefined) => {
    const errorMsg = await checkUploadFileCSV(file)
    if (errorMsg) {
      // t:ファイル添付エラー
      setMessageModal(t("thread:userMessageEdit.fileAttachmentError"), errorMsg);
      return;
    }
    if (file != null) {
      const rowCount = await getCsvRowCount(file)
      setSelectedFile(file);
      setSelectedFileRowCount(rowCount - 1);
      setSelectedFileName(file.name);
      setIsCsvConfirmModalOpen(true)
    }
  }

  return (
    <>
      {/* t:メンバー管理 */}
      <AdminPageTitle title={t("membership:title")} />
      <Divider />
      <AdminContentsWrapper id="scrollableDiv">
        {
          isLicenseLimitOver &&
          <Box sx={{ mt: '15px', mb: '25px', p: 2, backgroundColor: 'error.main', borderRadius: 1 }}>
            {/* t:ライセンス数超過 */}
            <Typography variant="subtitle1" gutterBottom sx={{ color: 'error.contrastText', fontWeight: 'bold' }}>
              {t("membership:license.exceeded.title")}
            </Typography>
            <Typography variant="body2" sx={{ color: 'error.contrastText', fontWeight: 'bold', whiteSpace: 'pre-line' }}>
               {/* t:現在のメンバー数がライセンス数を超過しているため、一部の機能に制限がかかっております。
                 サービスを快適にご利用いただくために、速やかにライセンス数の追加、もしくはメンバー数の調整をお願いいたします。 */}
              {t("membership:license.exceeded.message")}
            </Typography>
          </Box>
        }
        {
          currentSub.plan.type === PlanType.STARTER ?
            <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
              {/* t:同じ会社や部署の仲間を招待することで、一緒にWorkAIzerを使うことができます。
                ※ スタータープランではメンバーは原則10名までとなっております。 */}
              {t("membership:starter.description")}
            </Typography> :
            <>
              <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
                {/* t:メンバーを招待、削除、ロールや所属グループの変更ができます。
                「招待」では、個別にメンバー追加登録できます。
                「CSVアップロード」では、一度に500件まで登録可能できます。更新、削除には対応していません。 */}
                {t("membership:license.description")}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom
                sx={{ mt: '10px' }}>
                {/* t:ライセンス数：{{licenseAmount}} */}
                {t("membership:license.count", { licenseAmount })}
                &nbsp;&nbsp;&nbsp;
                {/* t:メンバー数：{{membersCount}} */}
                {t("membership:members.count", { membersCount: team.membersCount })}
              </Typography>
            </>
        }
        <Box sx={{ mt: '15px', mb: '25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* t:ライセンス数を超えるため招待できません */}
          <Tooltip title={isLicenseLimitReached ? t("membership:invite.disabled.tooltip") : null}>
            <span>
              <Box display="flex" gap={2}>
                <Button 
                  variant="outlined" color="primary"
                  onClick={handleMemberListDownload}>
                  <DownloadIcon />
                  {/* t:メンバー一覧をダウンロード */}
                  {t("membership:invite.memberListButton")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddClick}
                  disabled={isLicenseLimitReached}
                >
                  {/* t:招待 */}
                  {t("membership:invite.button")}
                </Button>
                {/* t:CSVアップロード */}
                <FileUploadButton
                  onFileSelect={onSelectFile}
                  currentFile={selectedFile}
                  text={t("membership:invite.uploadButton")}
                  disabled={isLicenseLimitReached}
                />
                <Button onClick={handleTemplateDownload}>
                  <DownloadIcon />
                  {/* t:CSVテンプレート */}
                  {t("membership:invite.templateButton")}
                </Button>
              </Box>
            </span>
          </Tooltip>
          {membershipGroups.length > 0 && <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='subtitle1' color="text.secondary">
                {/* t:グループで絞り込む： */}
                {t("membership:filter.group.label")}&nbsp;
              </Typography>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                <Select
                  value={selectedGroupId}
                  onChange={(e) => onChangeSelectedGroupId(e.target.value as string)}
                  displayEmpty
                >
                  <MenuItem value="">
                    {/* t:全メンバー */}
                    <em>{t("membership:filter.group.all")}</em>
                  </MenuItem>
                  {membershipGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </>}
        </Box>
        <InfiniteScroll
          dataLength={memberships.length}
          next={onScroll}
          hasMore={hasMore}
          loader={
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
              <CircularProgress />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <TableContainer component={Paper}>
            <Table>
              {!isSmallScreen && (
                <TableHead>
                  <TableRow>
                    {/* t:名前 */}
                    <TableCell>{t("membership:table.header.name")}</TableCell>
                    {/* t:メールアドレス */}
                    <TableCell>{t("membership:table.header.email")}</TableCell>
                    {/* t:ロール */}
                    <TableCell>{t("membership:table.header.role")}</TableCell>
                    {membershipGroups.length > 0 && <>
                      {/* t:所属グループ */}
                      <TableCell>{t("membership:table.header.group")}</TableCell>
                    </>}
                    {/* t:ステータス */}
                    <TableCell>{t("membership:table.header.status")}</TableCell>
                    {/* t:作成日時 */}
                    <TableCell>{t("common:info.createdAt")}</TableCell>
                    {/* t:更新日時 */}
                    <TableCell>{t("common:info.updatedAt")}</TableCell>
                    {/* t:操作 */}
                    <TableCell>{t("common:info.actions")}</TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {memberships.map((membership) => (
                  <ResponsiveTableRow key={membership.id}>
                    <ResponsiveTableRowHeader>{membership.user.name || membership.user.email}</ResponsiveTableRowHeader>
                    {/* t:メールアドレス */}
                    <ResponsiveTableCell data-label={t("membership:table.header.email")}>{membership.user.email}</ResponsiveTableCell>
                    {/* t:ロール */}
                    <ResponsiveTableCell data-label={t("membership:table.header.role")}>{getMembershipRoleLabel(t, membership.role)}</ResponsiveTableCell>
                    {membershipGroups.length > 0 && <>
                      {/* t:所属グループ */}
                      <ResponsiveTableCell data-label={t("membership:table.header.group")}>{membership.group?.name}</ResponsiveTableCell>
                    </>}
                    {/* t:ステータス */}
                    <ResponsiveTableCell data-label={t("membership:table.header.status")}>
                      {/* t:参加済/招待中 */}
                      {membership.isJoined ? t('membership:status.joined') : t('membership:status.invited')}
                      {membership.invite && !membership.invite.isApproved && (
                        <>
                          <br />
                          <div>
                            {/* t:承認期限：{{approveDeadline}} */}
                            ({t("membership:status.approveDeadline", { approveDeadline: displayTimestamp(membership.invite.approveDeadline) })})
                          </div>
                          <div>
                            {membership.invite.isApproveDeadlineExceeded && !membership.invite.isApproved && (<>
                              {/* t:期限切れ */}
                              <span style={{ color: 'red' }}>{t("membership:status.expired")}</span>
                              {/* t:再招待 */}
                              <Button size="small" onClick={() => handleReinvite(membership)}>{t("membership:action.reinvite")}</Button>
                            </>)}
                          </div>
                        </>
                      )}
                    </ResponsiveTableCell>
                    {/* t:作成日時 */}
                    <ResponsiveTableCell data-label={t("common:info.createdAt")}>{displayTimestamp(membership.createdAt)}</ResponsiveTableCell>
                    {/* t:更新日時 */}
                    <ResponsiveTableCell data-label={t("common:info.updatedAt")}>{displayTimestamp(membership.updatedAt)}</ResponsiveTableCell>
                    <ResponsiveTableActionFooter>
                      {(hasSuperAdminAuth(loginUser) || membershipGroups.length > 0) &&
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => handleEditClick(membership)}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                      {(hasSuperAdminAuth(loginUser) || (hasAdminAuth(loginUser) && membership.role === 'normal')) && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteClick(membership.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ResponsiveTableActionFooter>
                  </ResponsiveTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </AdminContentsWrapper>
      <MembershipFormModal
        open={isModalOpen}
        handleClose={handleModalClose}
        membershipGroups={membershipGroups}
        defaultValues={
          selectedMembership ?
            {
              // 更新
              id: selectedMembership.id,
              groupId: selectedMembership.group?.id,
              role: selectedMembership.role,
            } :
            {
              // 新規登録
              emails: "",
              role: MembershipRole.NORMAL
            }
        }
      />

      {/* CSVアップロードの確認ダイアログ */}
      <CsvConfirmModal
        open={isCsvConfirmModalOpen}
        onClose={() => {setIsCsvConfirmModalOpen(false); setSelectedFile(undefined);}}
        currentFile={selectedFile}
        rowCount={selectedFileRowCount}
        onCsvError={handleCsvError}
      />

      {/* CSVエラーダイアログ */}
      <CsvErrorModal
        open={isCsvErrorModalOpen}
        onClose={() => {setIsCsvErrorModalOpen(false); setSelectedFile(undefined);}}
        fileName={selectedFileName}
        csvErrors={csvErrors}
      />

    </>
  );
};

export default MembershipListPresenter;
