import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ServerSentEventResponse, abortGlobalController, apiSSE, getGlobalAbortController } from '@/lib/sse';
import {
  setCurrentThread,
  addThreadToTop,
  addMessagesToCurrentThread,
  updateAIMessageStatus,
  addMessageToAIMessage,
  addTitleToCurrentThread,
  updateThreadUsage,
  setThreadOnGenerating,
} from '@/components/features/thread/thread.slice';
import {
  CreateThreadRequest,
  PostMessageRequest,
  SseEventAddMessageToAIMessage,
  SseEventThreadCreated,
  SseEventMessageNodesAdded,
  SseEventUpdateMessageStatus,
  SseEventBlockedByDlp,
  SseEventQuotaExceeded,
  SseEventUpdateThreadTitle,
  MessageNode,
  SeeEventMaxDepthOver,
} from '@/components/features/thread/thread.type';
import { updateMentionedAiModelsAtLastThreadCreation, updateTeamUsage } from '../../auth/auth.slice';
import { showMessageModal, showUnknownErrorMessageModal } from '../../generic/messageModal.slice';
import useLoading from '../../generic/hooks/useLoading';
import apiAxios from '@/lib/axios';
import { RootState } from '@/store';
import { showToast } from '../../generic/toast.slice';
import { captureException } from '@sentry/react';
import { UserMessageType } from '../thread.constant';
import { useTranslation } from 'react-i18next';


type ThreadApiCallbacks = {
  onOpen?: (response: Response) => void;
  onMessage?: (event: ServerSentEventResponse) => void;
  onClose?: () => void;
  onError?: (error: any) => void;
  onThreadCreated?: (data: SseEventThreadCreated) => void;
  onAddCharsToThreadTitle?: (data: SseEventUpdateThreadTitle) => void;
  onMessageNodesAdded?: (data: SseEventMessageNodesAdded) => void;
  onUpdateAIMessageStatus?: (data: SseEventUpdateMessageStatus) => void;
  onAddMessageToAIMessage?: (data: SseEventAddMessageToAIMessage) => void;
  onBlockedByDlp?: (data: SseEventBlockedByDlp) => void;
  onQuotaExceeded?: (data: SseEventQuotaExceeded) => void;
  onMaxDepthOver?: (data: SeeEventMaxDepthOver) => void;
  onNotFountError?: () => void;
  onNavigated?: () => void;
};

const useThreadApi = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const currentThread = useSelector((state: RootState) => state.thread.currentThread);

  const handleSSE = (
    endpoint: string,
    jsonBody: object,
    callbacks: ThreadApiCallbacks = {},
  ) => {

    dispatch(setThreadOnGenerating(true));

    console.debug(
      'post stream SSE Request:',
      endpoint,
      jsonBody,
      callbacks
    )

    apiSSE(endpoint, {
      method: 'POST',
      jsonBody: jsonBody,
      signal: getGlobalAbortController(callbacks.onClose).signal,
      async onopen(response) {
        console.debug('post stream SSE Connected: response=', response);
        callbacks.onOpen?.(response);
      },
      onmessage(event) {
        callbacks.onMessage?.(event);

        if (!event.data) {
          console.error('post stream SSE Error: event=', event);
          return;
        }
        if (event.event === 'threadCreated') {
          const data = event.data as SseEventThreadCreated;
          const thread = data.thread;
          dispatch(setCurrentThread(thread));
          dispatch(addThreadToTop(thread));
          dispatch(updateTeamUsage(data.messageResult.newTeamUsage))

          const userMessage = data.messageResult.userMessageNode.userMessage;
          if (userMessage && userMessage.type == UserMessageType.NORMAL) {
            dispatch(updateMentionedAiModelsAtLastThreadCreation(
              userMessage.mentions.sort((a, b) => a.order - b.order).map((mention) => {
                return mention.aiModel.code;
              })
            ))
          }

          callbacks.onThreadCreated?.(data);

          // テストモードでの作成の場合は画面遷移をしない
          if (thread.isTestMode) return;

          // 少し遅れて実行
          setTimeout(() => {
            navigate(`/threads/${thread.id}`);
            callbacks.onNavigated?.();
          }, 0);
        }
        else if (event.event === 'updateThreadTitle') {
          const data = event.data as SseEventUpdateThreadTitle;
          dispatch(addTitleToCurrentThread(data));
          callbacks.onAddCharsToThreadTitle?.(data);
        }
        else if (event.event === 'messageNodesAdded') {
          const data = event.data as SseEventMessageNodesAdded;
          dispatch(addMessagesToCurrentThread(data));
          dispatch(updateTeamUsage(data.newTeamUsage))
          callbacks.onMessageNodesAdded?.(data);
        }
        else if (event.event === 'updateAIMessageStatus') {
          const data = event.data as SseEventUpdateMessageStatus;
          const aiMessage = data.aiMessage;
          dispatch(updateAIMessageStatus(aiMessage));
          if (data.newTeamUsage) {
            dispatch(updateTeamUsage(data.newTeamUsage))
          }
          if (data.newThreadUsage) {
            dispatch(updateThreadUsage(data.newThreadUsage))
          }
          callbacks.onUpdateAIMessageStatus?.(data);
        }
        else if (event.event === 'addMessageToAIMessage') {
          const data = event.data as SseEventAddMessageToAIMessage;
          dispatch(addMessageToAIMessage(data));
          callbacks.onAddMessageToAIMessage?.(data);
        }
        else if (event.event === 'blockedByDlp') {
          const data = event.data as SseEventBlockedByDlp;
          callbacks.onBlockedByDlp?.(data);
        }
        else if (event.event === 'quotaExceeded') {
          const data = event.data as SseEventQuotaExceeded;
          dispatch(updateTeamUsage(data.quotaCheckResult.usage))
          callbacks.onQuotaExceeded?.(data);
        }
        else if (event.event === 'maxDepthOver') {
          const data = event.data as SeeEventMaxDepthOver;
          callbacks.onMaxDepthOver?.(data);
        }
        else if (event.event === 'notFoundError') {
          dispatch(showMessageModal({
            // t:対象が存在しません
            title: t("thread:errors.noData"),
            // t:操作の対象となるデータが存在しないか、操作可能となる条件を満たしませんでした。画面をリロードしてもう一度お試しください。
            message: t("thread:errors.noDataDescription"),
          }))
          callbacks.onNotFountError?.();
        }
        else {
          console.error('post stream SSE Error: event=', event);
          dispatch(showUnknownErrorMessageModal());
          callbacks.onError?.(event);
        }
      },
      onclose() {
        console.debug('post stream SSE Disconnected');
        dispatch(setThreadOnGenerating(false));
        abortGlobalController(); // しっかり停止させる
        callbacks.onClose?.();
      },
      onerror(error) {
        console.error('post stream SSE Error: error=', error);
        dispatch(setThreadOnGenerating(false));
        abortGlobalController(); // しっかり停止させる
        callbacks.onError?.(error);
      },
    });
  };

  const createThread = (request: CreateThreadRequest, callbacks?: ThreadApiCallbacks): void => {
    const endpoint = '/threads';
    const jsonBody = request;
    handleSSE(endpoint, jsonBody, callbacks);
  };

  const postMessage = (threadId: string, request: PostMessageRequest, callbacks?: ThreadApiCallbacks): void => {
    const endpoint = `/threads/${threadId}/messages`;
    const jsonBody = request;
    handleSSE(endpoint, jsonBody, callbacks);
  };

  const retryAIMessage = (threadId: string, msgNodeId: string, aiMsgId: string, callbacks?: ThreadApiCallbacks): void => {
    const endpoint = `/threads/${threadId}/messages/${msgNodeId}/ai-messages/${aiMsgId}/retry`;
    handleSSE(endpoint, {}, callbacks);
  };

  const stopGeneration = async () => {
    console.debug('stopGeneration');

    // 全体ローディング
    setIsLoading(true);

    // まずは生成を停止する
    abortGlobalController();

    // もしcurrentThreadがない場合は終了 (まずないはずだが)
    if (!currentThread) {
      return;
    }

    // 生成状態を失敗(中断)に変更する
    try {
      const response = await apiAxios.post<MessageNode>(
        `/threads/${currentThread.id}/stop-generation`
      );
      const aiMessages = response.data.aiMessages;
      // t:生成を中断しました
      dispatch(showToast({message: t("thread:aiResponse.userAbort"), severity: 'success'}));
      aiMessages.forEach((aiMessage) => {
        dispatch(updateAIMessageStatus(aiMessage));
      });
    } catch (err) {
      console.debug("stop-generation error threadId=" + currentThread.id, err)
      captureException(err);
      dispatch(showUnknownErrorMessageModal());
    } finally {
      dispatch(setThreadOnGenerating(false));
      setIsLoading(false);
    }
  };

  return { createThread, postMessage, retryAIMessage, stopGeneration };
};

export default useThreadApi;
