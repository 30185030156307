import { useLocation } from "react-router-dom";
import RegisterForm from "../features/auth/containers/RegisterForm";

const RegisterPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const hsi = query.get("hsi");

  return <RegisterForm hubSpotId={hsi} />;
};

export default RegisterPage;
