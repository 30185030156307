import { FC, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

const EmailVerificationGuard: FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const user = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    // 未認証ユーザーはログインページへ
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    // メール認証済みのユーザーはトップページへ
    if (user?.emailVerified) {
      navigate('/');
    }
  }, [isAuthenticated, user, navigate]);

  // 認証済みでメール未認証の場合のみOutletを表示
  return (isAuthenticated && user && !user.emailVerified) ? <Outlet /> : null;
};

export default EmailVerificationGuard;
