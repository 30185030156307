import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { Plan } from '@/components/features/subscription/subscription.type';
import { ReqUpdateQuotaCustomization, ReqScheduleLicenseChange, ReqSchedulePlanChange, SvSubscriptionWithFull } from '../subscription.type';
import { useMessageModal } from '@/components/features/generic/hooks/useMessageModal';
import SvSubscriptionDetailPresenter from '../presenters/SvSubscriptionDetailPresenter';
import { AxiosError } from 'axios';
import { useConfirmModal } from '@/components/features/generic/hooks/useConfirmModal';
import { displayDate } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { PlanType } from '@/components/features/subscription/subscription.constant';

const SvSubscriptionDetailContainer: React.FC = () => {
  const { t } = useTranslation();
  const [ plans, setPlans ] = useState<Plan[]>([]);
  const [ subscriptions, setSubscriptions ] = useState<SvSubscriptionWithFull[]>([]);
  const { setUnknownErrorMessageModal, setMessageModal } = useMessageModal();
  const { setConfirmModal } = useConfirmModal();
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    if (!team) return

    const fetchData = async () => {
      try {
        const [rePlans, resSubscriptions] = await Promise.all([
          apiAxios.get<Plan[]>('/supervisor/plans'),
          apiAxios.get<SvSubscriptionWithFull[]>(`/supervisor/teams/${team.id}/subscriptions`),
        ]);
        let plans = rePlans.data;
        if (!loginUser?.supervisor?.hasPrivilege) {
          plans = plans.filter(plan => plan.type !== PlanType.DELETED);
        }

        setPlans(plans);
        setSubscriptions(resSubscriptions.data);
      } catch (error) {
        captureException(error);
        setUnknownErrorMessageModal();
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [team]);

  if (!team) {
    return null;
  }

  const handleSchedulePlanChange = async (data: ReqSchedulePlanChange) => {
    // t:プラン変更の確認
    let title = t("subscription:subscriptionChange.confirmPlanChange");
    // t:プランの変更を予約しますか？
    let content = t("subscription:subscriptionChange.confirmPlanChangeMessage");

    if (plans.some(plan => plan.id === data.nextPlanId && plan.type === 'deleted')) {
      // t:このチームを削除します。本当によろしいですか？
      title = t("subscription:deleteTeamConfirmation.title");
      // t: あなたは下記のチームを「{{applyStartedOn}}」に削除しようとしています。
      // 本当に削除して問題ないか。ご確認ください。
      // ----------------------------------------------
      // [チーム情報]
      // ID：{{teamId}}
      // 名前：{{teamName}}
      // 企業：{{companyName}}
      // ドメイン：{{primaryDomain}}
      // 作成者：{{creatorName}} ({{creatorEmail}})
      //
      // [特権管理者一覧]
      // {{privilegedAdmins}}
      //
      // [今月の利用状況]
      // スレッド作成数：{{threadNum}}
      // 生成回数：{{aiGenerateCount}}
      // 消費クレジット数：{{totalCredit}}
      //
      // [メモ]
      // {{memo}}
      // ----------------------------------------------
      content = t(
        "subscription:deleteTeamConfirmation.content",
        {
          applyStartedOn: displayDate(data.applyStartedOn),
          teamId: team.id,
          teamName: team.name,
          companyName: team.companyName,
          primaryDomain: team.primaryDomain,
          creatorName: team.creator?.name,
          creatorEmail: team.creator?.email,
          privilegedAdmins: team.memberships.map(member => `・${member.user.name}(${member.user.email})`).join('\n'),
          threadNum: team.usage.threadNum,
          aiGenerateCount: team.usage.aiGenerateCount,
          totalCredit: team.usage.totalCredit,
          memo: team.memo
        }
      );
    }

    setConfirmModal(
      title,
      content,
      async () => {
        try {
          await apiAxios.post(`/supervisor/teams/${team.id}/subscriptions/plan/scheduled`, data);
          // t:[予約成功]
          //   プランの変更予約が正常に完了しました。
          //   画面をリロードします。
          window.alert(t("subscription:subscriptionChange.successMessages.planChange"));
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            // t:エラー
            setMessageModal(t('common:message.unknownError'), error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorMessageModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const handleScheduleLicenseChange = async (data: ReqScheduleLicenseChange) => {
    setConfirmModal(
      // t:ライセンス変更の確認
      t("subscription:subscriptionChange.confirmLicenseChange"),
      // t:ライセンスの変更を予約しますか？
      t("subscription:subscriptionChange.confirmLicenseChangeMessage"),
      async () => {
        try {
          await apiAxios.post(`/supervisor/teams/${team.id}/subscriptions/license/scheduled`, data);
          // t:[予約成功]
          //   ライセンスの変更予約が正常に完了しました。
          //   画面をリロードします。
          window.alert(t("subscription:subscriptionChange.successMessages.licenseChange"));
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            // t:エラー
            setMessageModal(t('common:message.unknownError'), error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorMessageModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const handleUpdateQuotaCustomization = async (subscriptionId: string, data: ReqUpdateQuotaCustomization) => {
    setConfirmModal(
      // t:プランカスタマイズの確認
      t("subscription:subscriptionChange.confirmCustomization"),
      // t:プランのカスタマイズを実行しますか？
      t("subscription:subscriptionChange.confirmCustomizationMessage"),
      async () => {
        try {
          await apiAxios.put(`/supervisor/subscriptions/${subscriptionId}/quota-customization`, data);
          // t:[実行成功]
          //   プランのカスタマイズが正常に完了しました。
          //   画面をリロードします。
          window.alert(t("subscription:subscriptionChange.successMessages.customization"));
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            // t:エラー
            setMessageModal(t('common:message.unknownError'), error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorMessageModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const cancelScheduledPlanChange = async () => {
    setConfirmModal(
      // t:キャンセル確認
      t("subscription:subscriptionChange.cancelConfirmation"),
      // t:プランの変更予約をキャンセルしますか？
      t("subscription:subscriptionChange.cancelPlanChangeMessage"),
      async () => {
        try {
          await apiAxios.delete(`/supervisor/teams/${team.id}/subscriptions/plan/scheduled`);
          // t:[キャンセル成功]
          //   プランの変更予約がキャンセルされました。
          //   画面をリロードします。
          window.alert(t("subscription:subscriptionChange.successMessages.cancelPlanChange"));
          window.location.reload();
        } catch (error) {
          captureException(error);
          setUnknownErrorMessageModal();
          console.error('Failed to save data:', error);
        }
      }
    );
  };

  const handleCancelLicenseChangeScheduled = async () => { // FIXME: handleCancelHoge か cancelHoge かが不統一
    setConfirmModal(
      // t:キャンセル確認
      t("subscription:subscriptionChange.cancelConfirmation"),
      // t:ライセンスの変更予約をキャンセルしますか？
      t("subscription:subscriptionChange.cancelLicenseChangeMessage"),
      async () => {
        try {
          await apiAxios.delete(`/supervisor/teams/${team.id}/subscriptions/license/scheduled`);
          window.alert(
            t(
              // t:[キャンセル成功]
              // ライセンスの変更予約がキャンセルされました。
              // 画面をリロードします。
              "subscription:subscriptionChange.successMessages.cancelLicenseChange"
            )
          );
          window.location.reload();
        } catch (error) {
          captureException(error);
          setUnknownErrorMessageModal();
          console.error('Failed to save data:', error);
        }
      }
    );
  };

  return (
    <SvSubscriptionDetailPresenter
      team={team}
      plans={plans}
      subscriptions={subscriptions}
      onSchedulePlanChange={handleSchedulePlanChange}
      onScheduleLicenseChange={handleScheduleLicenseChange}
      onHandleQuotaCustomization={handleUpdateQuotaCustomization}
      onCancelPlanChange={cancelScheduledPlanChange}
      onCancelLicenseChange={handleCancelLicenseChangeScheduled}
    />
  );
};

export default SvSubscriptionDetailContainer;
