import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CenteredMainAreaWrapper, ContentsWrapper } from '@/components/ui/StyledComponents';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import theme from "@/theme";

const ResetPanel = styled(Box)`
  padding: 40px;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
    width: 100%;
    padding: 15px;
    border-radius: 0;
  }
`;

const ResetTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
  height: 48px;
`;

interface PasswordResetPresenterProps {
  form: UseFormReturn<{
    newPassword: string;
    confirmPassword: string;
  }>;
  onSubmit: (data: { newPassword: string; confirmPassword: string }) => Promise<void>;
  isResetSuccess: boolean;
  error: string | null;
  onGoToLogin: () => void;
}

const PasswordResetPresenter: React.FC<PasswordResetPresenterProps> = ({
  form,
  onSubmit,
  isResetSuccess,
  error,
  onGoToLogin,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors, isSubmitting } } = form;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <ContentsWrapper>
      <CenteredMainAreaWrapper>
        <ResetPanel>
          {/* t: パスワードのリセット */}
          <ResetTitle variant="h5">{t('auth:resetPassword')}</ResetTitle>

          {isResetSuccess ? (
            <Box>
              <Alert severity="success" sx={{ mb: 3 }}>
                {/* t: パスワードが正常にリセットされました */}
                {t('auth:passwordResetSuccess')}
              </Alert>
              <Typography variant="body1" paragraph>
                {/* t: 新しいパスワードでログインできるようになりました。 */}
                {t('auth:passwordResetSuccessDescription')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={onGoToLogin}
                fullWidth
                sx={{ mt: 2 }}
              >
                {/* t: ログイン画面へ */}
                {t('auth:goToLogin')}
              </Button>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1" paragraph>
                {/* t: 新しいパスワードを設定してください。 */}
                {t('auth:setNewPassword')}
              </Typography>

              {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {error}
                </Alert>
              )}

              <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* t: 新しいパスワード */}
                <TextField
                  label={t('auth:newPassword')}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  margin="normal"
                  required
                  autoFocus
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                  {...register('newPassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          tabIndex={-1}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {/* t: 新しいパスワード（確認） */}
                <TextField
                  label={t('auth:confirmNewPassword')}
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  margin="normal"
                  required
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                  {...register('confirmPassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleToggleConfirmPasswordVisibility}
                          edge="end"
                          tabIndex={-1}
                        >
                          {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <FormHelperText>
                  {/* t:パスワードは8文字以上で、英大文字、英小文字、数字、記号(@$!%*?&-_)のうち2種類以上を含む必要があります。 */}
                  {t("common:message.passwordHelperText")}
                </FormHelperText>

                <Box sx={{ mt: 3 }}>
                  {/* t: パスワードをリセット */}
                  <SubmitButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    disabled={isSubmitting}
                  >
                    {t('auth:resetPassword')}
                  </SubmitButton>
                </Box>
              </Box>
            </Box>
          )}
        </ResetPanel>
      </CenteredMainAreaWrapper>
    </ContentsWrapper>
  );
};

export default PasswordResetPresenter;
