import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { SvTeamCreate, SvTeamUpdate } from '../team.type';
import { createSvTeam, updateSvTeam } from '../team.slice';
import { AppDispatch } from '@/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { z } from '@/lib/zod';
import { WaTextField } from '@/components/ui/WaTextField';
import { useTranslation } from 'react-i18next';

type SvTeamSave = SvTeamCreate | SvTeamUpdate;

const baseSchema = z.object({
  name: z.string().min(1).max(100),
  companyName: z.string().max(100).nullable(),
  primaryDomain: z.string().max(100).nullable(),
  salesforceUrl: z.string().url().nullable().or(z.literal('')),
  hubSpotId: z.string().max(255).nullable(),
  memo: z.string().max(10000).nullable(),
});

const createSchema = baseSchema.extend({
  superAdminEmail: z.string().min(1).email(),
});

const updateSchema = baseSchema;

interface Props {
  open: boolean;
  handleClose: () => void;
  defaultValues?: SvTeamSave;
}

const SvTeamFormModal: React.FC<Props> = ({
  open,
  handleClose,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const innerDefaultValues : SvTeamSave = defaultValues || {name: "", superAdminEmail: ""};
  const isEdit = 'id' in innerDefaultValues;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: zodResolver(isEdit ? updateSchema : createSchema),
    defaultValues: innerDefaultValues,
  });
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    reset(innerDefaultValues);
  }, [defaultValues, reset]);

  if(!loginUser) {
    return null;
  }

  const onSubmit = async (data: SvTeamSave) => {
    setIsLoading(true)
    if (isEdit) {
      const _data = data as SvTeamUpdate;
      _data.id = innerDefaultValues?.id;
      await dispatch(updateSvTeam(_data));
    } else {
      await dispatch(createSvTeam(data as SvTeamCreate));
    }
    setIsLoading(false)
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      {/* t:チーム更新/チーム追加 */}
      <DialogTitle>{isEdit ? t("teams:teamForm.update") : t("teams:teamForm.create")}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.name)}>
            {/* t:チーム名 */}
            <WaTextField
              label={t("teams:teamForm.fields.teamName")}
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </FormControl>
          { !isEdit &&
            <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.superAdminEmail)}>
              {/* t:特権管理者メールアドレス */}
              <WaTextField
                label={t("teams:teamForm.fields.superAdminEmail")}
                variant="outlined"
                {...register('superAdminEmail')}
                error={!!errors.superAdminEmail}
                helperText={errors.superAdminEmail?.message}
              />
            </FormControl>
          }
          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.companyName)}>
            {/* t:会社名 */}
            <WaTextField
              label={t("teams:teamForm.fields.companyName")}
              variant="outlined"
              {...register('companyName')}
              error={!!errors.companyName}
              helperText={errors.companyName?.message}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.primaryDomain)}>
            {/* t:プライマリードメイン */}
            <WaTextField
              label={t("teams:teamForm.fields.primaryDomain")}
              variant="outlined"
              {...register('primaryDomain')}
              error={!!errors.primaryDomain}
              helperText={errors.primaryDomain?.message}
            />
            {/* t:※ あくまで運用側での管理に使う情報です。 */}
            <FormHelperText>
              {t("teams:teamForm.domainHelp")}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.salesforceUrl)}>
            {/* t:Salesforce商談URL */}
            <WaTextField
              label={t("teams:teamForm.fields.salesforceUrl")}
              variant="outlined"
              {...register('salesforceUrl')}
              error={!!errors.salesforceUrl}
              helperText={errors.salesforceUrl?.message}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.hubSpotId)}>
            <WaTextField
              label={"HubSpot ID"}
              variant="outlined"
              {...register('hubSpotId')}
              error={!!errors.hubSpotId}
              helperText={errors.hubSpotId?.message}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.memo)}>
            {/* t:メモ */}
            <WaTextField
              label={t("teams:teamForm.fields.memo")}
              variant="outlined"
              multiline
              rows={4}
              {...register('memo')}
              error={!!errors.memo}
              helperText={errors.memo?.message}
            />
          </FormControl>
          <DialogActions style={{ marginTop: '20px' }}>
            <Button onClick={handleClose}>
              {/* t:キャンセル */}
              {t("common:button.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={24} /> : null}
            >
              {/* t:保存 */}
              {t("common:button.save")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SvTeamFormModal;
