import { FC, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

const EmailVerifyGuard: FC = () => {
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    if (loginUser && !loginUser.emailVerified) {
      navigate('/verify-email');
    }
  }, [loginUser, navigate]);

  return loginUser && loginUser.emailVerified ?  <Outlet /> : null;
};

export default EmailVerifyGuard;
