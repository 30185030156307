import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableCell,
  TableRow,
  Table,
  TableContainer,
  Paper,
  TableBody,
  useTheme,

} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from "react-i18next";
import { MemberCsvValidateRowError } from "../membership.type";

interface CsvErrorModalProps {
  open: boolean;
  onClose: () => void;
  fileName: string;
  csvErrors: MemberCsvValidateRowError[];
}

const CsvErrorModal: React.FC<CsvErrorModalProps> = ({
  open,
  onClose,
  fileName,
  csvErrors
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const downloadCsvErrors = (errors: MemberCsvValidateRowError[]) => {
    const errorText = errors
      .map((err) => 
        `${t("membership:invite.csv.errorRow", {row:err.row})}${t(`membership:invite.csv.validationError.${err.errorCode}`)}`
      )
      .join("\n");

    const blob = new Blob([errorText], { type: "text/plain;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "csv_errors.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {/* t:CSVエラー */}
        {t("membership:invite.csv.errorTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: "pre-line" }}>
          {/* t:アップロードされたファイルに不備があります。
          以下の問題を確認の上、修正して再度アップロードしてください。 */}
          {t("membership:invite.csv.errorMessage")}
        </DialogContentText>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {/* t:ファイル名 */}
                  {t("membership:invite.csv.fileName")}
                </TableCell>
                <TableCell>
                  {fileName}
                </TableCell>
              </TableRow>
              {csvErrors.map((error, index) => (
              <TableRow key={index}>
                {index === 0 && (
                  <TableCell rowSpan={csvErrors.length} sx={{ whiteSpace: "nowrap", verticalAlign: "top" }}>
                    {/* t:エラー内容 */}
                    {t("membership:invite.csv.errorDetail")}
                  </TableCell>
                )}
                <TableCell sx={{ fontWeight: "bold", color: theme.palette.error.main }}>
                  {/* エラー内容詳細 */}
                  {/* t:{{row}}行目:{{エラー詳細}} */}
                  {/* 以下はサンプル */}
                  {/* t:1行目:メールアドレス列を入力してください。 */}
                  {t("membership:invite.csv.errorRow", {row:error.row})}{t(`membership:invite.csv.validationError.${error.errorCode}`)}
                </TableCell>
              </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => downloadCsvErrors(csvErrors)}>
          <DownloadIcon />
          {/* t:エラー内容を保存 */}
          {t("membership:invite.csv.errorDownload")}
        </Button>
        <Button onClick={onClose} color="primary" variant="contained">
          {/* t:閉じる */}
          {t("common:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CsvErrorModal;
