import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";

// firebaseConfig は環境変数や定数ファイルからインポート
const projectId = import.meta.env.VITE_FIREBASE_GCP_PROJECT_ID;
const appId = import.meta.env.VITE_FIREBASE_APP_ID;
const emulatorHost = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST;
const apiKey = import.meta.env.VITE_FIREBASE_API_KEY;
const authDomain = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;

const firebaseConfig = {
    apiKey,
    authDomain,
    projectId,
    appId,
};

// 既に初期化されていなければ初期化する
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

// ローカル環境（またはエミュレーターを使う環境）ならエミュレーターに接続
if (emulatorHost) {
    connectAuthEmulator(firebaseAuth, `http://${emulatorHost}`, { disableWarnings: true });
}

export default firebaseApp;
