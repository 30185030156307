import { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '@/store';
import apiAxios from '@/lib/axios';
import { QuickPromptWithSetAndVersion } from '../../quickPrompt/quickPrompt.type';
import { QpEditStep1Data, QpEditStep2Data, QpEditStep3Data, QpEditStep4Data } from '../qpEditForm.type';
import QpEditPresenter from '../presenters/QpEditPresenter';
import useLoading from '../../generic/hooks/useLoading';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../generic/hooks/useToast';
import { useTranslation } from 'react-i18next';
import { createQuickPromptWithSet, updateQpManagement, updateQuickPromptWithSet } from '../../quickPrompt/quickPrompt.slice';
import { NEW_QP_SET } from './QpEditStep1BasicInfoContainer';
import { useSelector } from 'react-redux';
import { translateJinjaErrorMessage } from '../qpEditForm.utils';
import { QpAuthorityType } from '../../quickPrompt/quickPrompt.constant';

interface StepCompletionStatus {
  [key: number]: boolean;
}

interface QpEditProps {
  quickPromptId?: string;
  isCopyMode?: boolean;
}

function QpEditContainer({
  quickPromptId = undefined,
  isCopyMode = false,
}: QpEditProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { setIsLoading } = useLoading();
  const { setUnknownErrorMessageModal, setMessageModal } = useMessageModal();
  const { qpManagement } = useSelector((state: RootState) => state.quickPrompt);
  // 編集対象のQPの権限 (編集モードでない場合はundefined)
  const [ authorityType, setAuthorityType ] = useState<QpAuthorityType | undefined>();

  // 編集対象または複製対象のQPが渡されているか?
  const passedQp = Boolean(quickPromptId);
  // 編集モードか？
  const isEditMode = !isCopyMode && passedQp;

  // 編集データ
  const [step1Data, setStep1Data] = useState<QpEditStep1Data>({});
  const [step2Data, setStep2Data] = useState<QpEditStep2Data>({});
  const [step3Data, setStep3Data] = useState<QpEditStep3Data>({});
  const [step4Data, setStep4Data] = useState<QpEditStep4Data>({});
  // 現在のステップ
  const [currentStep, setCurrentStep] = useState(1);
  // 各ステップの完了状態
  // - 編集モードの場合は最初から全てtrueとする
  const [stepCompletionStatus, setStepCompletionStatus] = useState<StepCompletionStatus>({
    1: passedQp,
    2: passedQp,
    3: passedQp,
  });
  // 編集対象or複製のデータは用意されてるか
  // - quickPromptIdが渡されてる場合はfalseとし、データが取得できたらtrueにする
  // - それ以外の場合は最初からtrueとする
  const [isEditDataLoaded, setIsEditDataLoaded] = useState(!passedQp);

  // クイックプロンプトを取得する
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const path = `/quick-prompts/${quickPromptId}`;
      const response = await apiAxios.get<QuickPromptWithSetAndVersion>(path);
      const data = response.data;
      if (isEditMode) {
        setAuthorityType(data.authorityType);
      }
      setStep1Data({
        setId: isCopyMode ? undefined : data.quickPromptSetId,
        // t: 複製時の名前の後ろに付与する文字列 (例: "コピー")
        name: data.name + (isCopyMode ? t("quickPromptEdit:messages.copiedNameSuffix") : ""),
        description: data.description,
        shortName: data.shortName,
        shortDescription: data.shortDescription,
        icon: data.icon,
      });
      setStep2Data({
        // UIの都合でIDを付与
        properties: data.latestVersion.properties?.map((property, index) => ({
          ...property,
          id: String(index),
        })) ?? [],
      });
      setStep3Data({
        promptTemplate: data.latestVersion.promptTemplate
      });
      setIsLoading(false);
      setIsEditDataLoaded(true);
    };
    if (quickPromptId) {
      fetch();
    }
  }, [dispatch, quickPromptId, setIsLoading, isCopyMode]);

  // 仮のクイックプロンプトをAPIから取得
  useEffect(() => {
    if (currentStep === 4) {
      handleSave(true);
    }
  }, [currentStep]);

  const handleSave = async (isDraft: boolean|undefined = undefined) => {
    setIsLoading(true);
    console.debug(
      "step1Data:", step1Data,
      "step2Data:", step2Data,
      "step3Data:", step3Data,
      "isDraft:", isDraft
    );
    try {
      const isNewSetSelected = step1Data.setId === NEW_QP_SET;
      const body = {
        ...step1Data,
        ...step2Data,
        ...step3Data,
        // 新規セットが選択されている場合は、セットIDを空にしつつ新しいセット名を送信
        // ただし下書きの場合はundefinedを渡す
        setId:
          (!isNewSetSelected && !isDraft) ? step1Data.setId : undefined,
        newGroupName:
          (isNewSetSelected && !isDraft) ? step1Data.newSetName : undefined,
        // 下書き保存の場合はtrueを送信
        isDraft: isDraft,
      };

      // ステップ3のテンプレートが正常かチェック
      if (!(await isValidPromptTemplate())) {
        setIsLoading(false);
        return;
      }

      const updateSetOrderAndExpandIfNewSet = (setId: string) => {
        if (!isNewSetSelected) {
          return;
        }
        // 並び順を更新
        const newQpManagement = {
          ...qpManagement,
          orderedSetIds: [setId, ...qpManagement.orderedSetIds],
        };
        dispatch(updateQpManagement(newQpManagement));

        // サイドバーは開いた状態に
        const storedExpandedSets = localStorage.getItem('expandedSets');
        const newExpandedSets = storedExpandedSets
          ? JSON.parse(storedExpandedSets)
          : {};
        newExpandedSets[setId] = true;
        localStorage.setItem('expandedSets', JSON.stringify(newExpandedSets));
      };

      if (isDraft) {
        // 下書き保存の場合 (Step4への移動)
        const response = await apiAxios.post<QuickPromptWithSetAndVersion>(
          '/quick-prompts',
          body,
        );
        setStep4Data({confirmQp: response.data});
      } else if (isEditMode) {
        // 更新の場合
        const response = await apiAxios.put<QuickPromptWithSetAndVersion>(
          `/quick-prompts/${quickPromptId}`,
          body,
        );
        updateSetOrderAndExpandIfNewSet(response.data.quickPromptSetId);
        dispatch(updateQuickPromptWithSet(response.data));
        showToast(t('common:message.saved'), 'success');
        navigate('/');
      } else {
        // 新規登録の場合
        const response = await apiAxios.post<QuickPromptWithSetAndVersion>(
          '/quick-prompts',
          body,
        );
        updateSetOrderAndExpandIfNewSet(response.data.quickPromptSetId);
        dispatch(createQuickPromptWithSet(response.data));
        showToast(t('common:message.saved'), 'success');
        navigate('/');
      }
    } catch (error) {
      console.debug('/quick-prompts/confirm', error);
      captureException(error);
      setUnknownErrorMessageModal();
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeData = (step: number, data: QpEditStep1Data|QpEditStep2Data|QpEditStep3Data) => {
    switch (step) {
      case 1:
        setStep1Data(data as QpEditStep1Data);
        break;
      case 2:
        setStep2Data(data as QpEditStep2Data);
        break;
      case 3:
        setStep3Data(data as QpEditStep3Data);
        break;
      default:
        break;
    }
  };

  const changeStepCompletion = (step: number, completed: boolean) => {
    setStepCompletionStatus((prev) => ({
      ...prev,
      [step]: completed,
    }));
  };

  const isStepSelectable = (step: number) => {
    // 現在のステップとより前のステップは常に選択可能
    if (step <= currentStep) {
      return true;
    }
    // ステップが4の場合は1〜3が全てcompletedならtrueを返す
    if (step === 4) {
      return Object.values(stepCompletionStatus).every((status) => status);
    }
    return (
      // 現在のステップが完了状態
      stepCompletionStatus[currentStep] &&
      // かつ、対象ステップの一つ前のステップも完了状態
      stepCompletionStatus[step - 1]
    );
  };

  const isValidPromptTemplate = async () => {
    if (step3Data.promptTemplate === "") {
      return false;
    }
    try {
      setIsLoading(true);
      const response = await apiAxios.post('/quick-prompts/check-template', {
        promptTemplate: step3Data.promptTemplate,
      });

      const result = response.data;
      setIsLoading(false);

      if (result.result === "success") {
        return true;
      } else {
        const lines = (step3Data.promptTemplate || "").split('\n');
        const lineNumber = result.failureDetail.line;
        const start = Math.max(0, lineNumber - 3);
        const end = Math.min(lines.length - 1, lineNumber + 1);

        const relevantLines = lines.slice(start, end + 1)
          .map((line, index) => {
            const currentLineNumber = start + index + 1;
            if (currentLineNumber === lineNumber) {
              return `${line} <<<======`;
            }
            return `${line}`;
          })
          .join('\n')
        ;

        setMessageModal(
          // t:プロンプトテンプレート文法エラー
          t("quickPromptEdit:messages.templateSyntaxErrorTitle"),
          // t:テンプレートのエラーの文法に問題があります。\n\n[エラー内容]\n__MESSAGE__\n\n[該当箇所]\n__LINES__
          t("quickPromptEdit:messages.templateSyntaxErrorDetail", {
            message: translateJinjaErrorMessage(result.failureDetail.message),
            lines: relevantLines,
          })
        );
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error checking template:', error);
      return false;
    }
  };

  const handleStepClick = async (targetStep: number) => {
    // 移動前がステップ3の場合はテンプレートのチェックを行う
    if (currentStep === 3 && !(await isValidPromptTemplate())) {
      return;
    }
    if (isStepSelectable(targetStep)) {
      setCurrentStep(targetStep);
    }
  };

  const handleNextClick = async () => {
    // 移動前がステップ3の場合はテンプレートのチェックを行う
    if (currentStep === 3 && !(await isValidPromptTemplate())) {
      return;
    }
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  if (!isEditDataLoaded) {
    return null;
  }

  const handleBackClick = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const isAllCompleted = Object.values(stepCompletionStatus).every((status) => status);

  return (
    <QpEditPresenter
      currentStep={currentStep}
      authorityType={authorityType}
      step1Data={step1Data}
      step2Data={step2Data}
      step3Data={step3Data}
      step4Data={step4Data}
      isEditMode={isEditMode}
      isStepSelectable={isStepSelectable}
      canSave={isAllCompleted}
      onSave={() => handleSave(false)}
      onChangeData={onChangeData}
      onChangeStepComplete={changeStepCompletion}
      onStepClick={handleStepClick}
      onNextClick={handleNextClick}
      onBackClick={handleBackClick}
    />
  );
}

export default QpEditContainer;
