import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { News, NewsInput } from './news.type';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { showUnknownErrorMessageModal } from '../../generic/messageModal.slice';
import { showToast } from '../../generic/toast.slice';
import i18next from 'i18next';

interface NewsState {
  newsList: News[];
  loading: boolean;
  error: string | null;
  currentNews: News | null;
}

const initialState: NewsState = {
  newsList: [],
  loading: false,
  error: null,
  currentNews: null,
};

export const fetchNews = createAsyncThunk(
  'news/fetchNews',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.get<News[]>('/supervisor/news');
      return response.data;
    } catch (err) {
      console.debug("/news error", err);
      captureException(err);
      dispatch(showUnknownErrorMessageModal());
      return rejectWithValue('お知らせの取得に失敗しました');
    }
  }
);

export const fetchNewsById = createAsyncThunk(
  'news/fetchNewsById',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.get<News>(`/supervisor/news/${id}`);
      return response.data;
    } catch (err) {
      console.debug(`/news/${id} error`, err);
      captureException(err);
      dispatch(showUnknownErrorMessageModal());
      return rejectWithValue('お知らせの取得に失敗しました');
    }
  }
);

export const createNews = createAsyncThunk(
  'news/createNews',
  async (news: NewsInput, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.post<News>('/supervisor/news', news);
      dispatch(showToast({
        // t:お知らせを作成しました
        message: i18next.t('news:supervisor.messages.created'),
        severity: 'success'
      }));
      return response.data;
    } catch (err) {
      console.debug("/news create error", err);
      captureException(err);
      dispatch(showUnknownErrorMessageModal());
      return rejectWithValue('お知らせの作成に失敗しました');
    }
  }
);

export const updateNews = createAsyncThunk(
  'news/updateNews',
  async (news: NewsInput, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.put<News>(`/supervisor/news/${news.id}`, news);
      dispatch(showToast({
        // t:お知らせを更新しました
        message: i18next.t('news:supervisor.messages.updated'),
        severity: 'success'
      }));
      return response.data;
    } catch (err) {
      console.debug(`/news/${news.id} update error`, err);
      captureException(err);
      dispatch(showUnknownErrorMessageModal());
      return rejectWithValue('お知らせの更新に失敗しました');
    }
  }
);

export const deleteNews = createAsyncThunk(
  'news/deleteNews',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await apiAxios.delete(`/supervisor/news/${id}`);
      dispatch(showToast({
        // t:お知らせを削除しました
        message: i18next.t('news:supervisor.messages.deleted'),
        severity: 'success'
      }));
      return id;
    } catch (err) {
      console.debug(`/news/${id} delete error`, err);
      captureException(err);
      dispatch(showUnknownErrorMessageModal());
      return rejectWithValue('お知らせの削除に失敗しました');
    }
  }
);

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.newsList = action.payload;
    },
    appendNews: (state, action) => {
      state.newsList = [...state.newsList, ...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.loading = false;
        state.newsList = action.payload;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchNewsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewsById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentNews = action.payload;
      })
      .addCase(fetchNewsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(createNews.fulfilled, (state, action) => {
        state.newsList.push(action.payload);
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        const index = state.newsList.findIndex(news => news.id === action.payload.id);
        if (index !== -1) {
          state.newsList[index] = action.payload;
        }
      })
      .addCase(deleteNews.fulfilled, (state, action) => {
        state.newsList = state.newsList.filter(news => news.id !== action.payload);
      });
  },
});

export const {
    setNews,
    appendNews,
} = newsSlice.actions;

export default newsSlice.reducer;
