import React from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Divider,
} from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import theme from "@/theme";
import { CenteredMainAreaWrapper, ContentsWrapper } from '@/components/ui/StyledComponents';

const VerificationPanel = styled(Paper)`
  padding: 40px;
  max-width: 700px;
  width: 100%;
  border-radius: 8px;
  border: none;
  margin: 0 auto;
  box-shadow: none;

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
    width: 100%;
    padding: 15px;
    border-radius: 0;
  }
`;

const VerificationTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
`;

const IconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: ${theme.palette.primary.main};
`;

interface EmailVerificationCodePresenterProps {
  status: 'verifying' | 'success' | 'error';
  errorMessage: string;
  onContinue: () => void;
  onRetry: () => void;
  canCreateTeam: boolean;
}

const EmailVerificationCodePresenter: React.FC<EmailVerificationCodePresenterProps> = ({
  status,
  errorMessage,
  onContinue,
  onRetry,
  canCreateTeam,
}) => {
  const { t } = useTranslation();

  const renderContent = () => {
    switch (status) {
      case 'verifying':
        return (
          <>
            <IconWrapper>
              <HourglassTopIcon sx={{ fontSize: 64 }} />
            </IconWrapper>

            {/* t: メールアドレスを確認中 */}
            <VerificationTitle variant="h5">
              {t('auth:verifyingEmail')}
            </VerificationTitle>

            <Typography variant="body1" align="center" paragraph>
              {/* t: しばらくお待ちください */}
              {t('auth:pleaseWait')}
            </Typography>
          </>
        );

      case 'success':
        return (
          <>
            <IconWrapper>
              <CheckCircleOutlineIcon sx={{ fontSize: 64, color: theme.palette.success.main }} />
            </IconWrapper>

            {/* t: メールアドレスの確認が完了しました */}
            <VerificationTitle variant="h5">
              {t('auth:emailVerificationSuccess')}
            </VerificationTitle>

            {
              canCreateTeam ?
              <>
                <Typography variant="body1" align="center" sx={{ mb: 1, whiteSpace: 'pre-line' }}>
                  {/* t: メールアドレスの確認が完了しました。 続いてチームを作成しましょう。*/}
                  {t('auth:emailVerificationSuccessDescriptionAndCreateTeam')}
                </Typography>

                <Typography variant="body1" align="center" paragraph sx={{ mb: 3 }}>
                  {/*
                    t: WorkAIzerを利用するには、まずチームを作成する必要があります。
                    チームを作成することで、メンバーを招待し、仕事仲間と生成AIを使うことができます。
                  */}
                  {t("team:create.description")}
                </Typography>
              </> :
              <>
                <Typography variant="body1" align="center" sx={{ mb: 3, whiteSpace: 'pre-line' }}>
                  {/* t: メールアドレスの確認が完了しました。 */}
                  {t('auth:emailVerificationSuccessDescription')}
                </Typography>
              </>
            }

            <Box textAlign={'center'}>
              <Button
                variant="contained"
                color="primary"
                onClick={onContinue}
                endIcon={<ArrowForwardIcon />}
              >
                {
                  canCreateTeam ?
                  <>
                    {/* t: チーム作成に進む */}
                    {t('auth:continueToCreateTeam')}
                  </> :
                  <>
                    {/* t: トップページに戻る */}
                    {t('auth:continueToApp')}
                  </>
                }
              </Button>
            </Box>
          </>
        );

      case 'error':
        return (
          <>
            <IconWrapper>
              <ErrorOutlineIcon sx={{ fontSize: 64, color: theme.palette.error.main }} />
            </IconWrapper>

            {/* t: メールアドレスの確認に失敗しました */}
            <VerificationTitle variant="h5">
              {t('auth:emailVerificationFailed')}
            </VerificationTitle>

            <Typography variant="body1" color="error" align="center" paragraph sx={{ mb: 3 }}>
              {errorMessage}
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={onRetry}
                startIcon={<RefreshIcon />}
                fullWidth
              >
                {/* t: 再試行する */}
                {t('auth:tryAgain')}
              </Button>
            </Box>
          </>
        );
    }
  };

  return (
    <ContentsWrapper>
      <CenteredMainAreaWrapper>
        <VerificationPanel elevation={0}>
          {renderContent()}
        </VerificationPanel>
      </CenteredMainAreaWrapper>
    </ContentsWrapper>
  );
};

export default EmailVerificationCodePresenter;
