import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';

export interface MessageModalContent {
  title: string;
  message: string;
  buttonOption?: {
    title: string;
    url: string;
  };
}

interface MessageModalState {
  content: MessageModalContent | null;
}

const initialState: MessageModalState = {
  content: null,
};

const messageModalSlice = createSlice({
  name: 'messageModal',
  initialState,
  reducers: {
    showUnknownErrorMessageModal: (state) => {
      state.content = {
        title: i18next.t('common:message.unknownError'),
        message: i18next.t('common:message.unknownErrorFull'),
      };
    },
    showMessageModal: (state, action: PayloadAction<MessageModalContent>) => {
      state.content = action.payload;
    },
    hideMessageModal: (state) => {
      state.content = null;
    },
  },
});

export const { showUnknownErrorMessageModal, showMessageModal, hideMessageModal } = messageModalSlice.actions;
export default messageModalSlice.reducer;
