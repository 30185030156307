import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { appendNews } from '../news.slice';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, List, ListItemButton, Chip, Button, CircularProgress, Grid } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import apiAxios from '@/lib/axios';
import { News } from '../news.type';
import { captureException } from '@sentry/react';
import { useMessageModal } from '@/components/features/generic/hooks/useMessageModal';
import { styled } from '@mui/system';
import CommonPanel from '@/components/ui/CommonPanel';
import { useTranslation } from 'react-i18next';
import { convertStrToLanguage } from '../../setting/setting.utils';
import { displayDate } from '@/lib/utils';

const LIMIT = 20;

const NewsListWrapper = styled('div')(({ theme }) => ({
  marginBottom: '20px',
  [theme.breakpoints.up('md')]: {
    width: '1000px',
  },
}));

const NewsListItem = styled(ListItemButton)`
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  &:last-child {
    border-bottom: none;
  }
`;

const NewsList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const newsList = useSelector((state: RootState) => state.news.newsList);
  const [hasMore, setHasMore] = useState(true);
  const { setUnknownErrorMessageModal } = useMessageModal();
  const { t, i18n } = useTranslation();

  const handleScroll = async () => {
    try {
      const response = await apiAxios.get<News[]>('/news', {
        params: {
          offset: newsList.length,
          limit: LIMIT,
          lang: convertStrToLanguage(i18n.language),
        },
      });
      dispatch(appendNews(response.data));
      if (response.data.length < LIMIT) {
        setHasMore(false);
      }
    } catch (error) {
      captureException(error);
      setUnknownErrorMessageModal();
      // t:ニュースの取得に失敗しました
      console.error(t('news:fetchError'), error);
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  const handleNewsClick = (news: News) => {
    if (news.type === 'internal') {
      navigate(`/news/${news.id}`);
    } else {
      window.open(news.externalUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <CommonPanel>
      <NewsListWrapper>
        <Box mb={3}>
          <Button onClick={() => navigate(-1)} variant="text" color="primary">
            {/* t:← 戻る */}
            {t("news:back")}
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5" component="h1" fontWeight="bold">
            {/* t:お知らせ一覧 */}
            {t("news:title")}
          </Typography>
        </Box>
        <InfiniteScroll
          dataLength={newsList.length}
          next={handleScroll}
          hasMore={hasMore}
          loader={
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress />
            </Box>
          }
        >
          <List>
            {newsList.map((news) => (
              <NewsListItem
                key={news.id}
                onClick={() => handleNewsClick(news)}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body2" color="text.secondary">
                      {displayDate(new Date(news.publishedAt))}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      {news.isImportant && (<>
                        <Chip variant="outlined"
                          // t:大事なお知らせ
                          label={t("news:important")}
                          color="error" size="small" sx={{ mr: 1, mb: 1 }} />
                        </>
                      )}
                      <Typography variant="body1" fontWeight="medium">
                        {news.title}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </NewsListItem>
            ))}
          </List>
        </InfiniteScroll>
      </NewsListWrapper>
    </CommonPanel>
  );
};

export default NewsList;
