import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, DialogProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

type MessageModalPresenterProps = {
  isOpen: boolean;
  title: string | null;
  message: string | null;
  buttonOption: { title: string; url: string } | null | undefined;
  onClose: () => void;
};

const MessageModalPresenter: React.FC<MessageModalPresenterProps> = ({
  isOpen,
  title,
  message,
  buttonOption,
  onClose,
}) => {
  const [maxWidth, _] = React.useState<DialogProps['maxWidth']>('sm');
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message && message.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonOption && (
          <Button href={buttonOption.url} target="_blank" rel="noopener noreferrer">
            {buttonOption.title}
          </Button>
        )}
        <Button onClick={onClose}>{t('common:button.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageModalPresenter;
