import { AIModelConst, AIModelConsts } from "../aiModel/aiModel.constant";
import { MembershipRole } from "../membership/membership.constant";
import { MemberInvite, Membership } from "../membership/membership.type";
import { AILanguage, Language } from "../setting/setting.constant";
import { SubscriptionDetail } from "../subscription/subscription.type";
import { TeamLogo, TeamWithSubscription } from "../team/team.type";

export type User = {
  id: string;
  name: string;
  email: string;
  emailVerified: boolean;
  picture?: string;
  hasPasswordAuth: boolean;
  hasGoogleAuth: boolean;
  language?: Language;
  aiLanguage?: AILanguage;
  aiLanguageOther?: string;
  canCreateTeam: boolean;
}

export type Supervisor = {
  id: string;
  hasPrivilege: boolean;
}

export type LoginUser = User & {
  memberships: LoginUserMembership[];
  supervisor?: Supervisor;
}

export type LoginUserTeam = TeamWithSubscription & {
  membersCount: number;
  logo?: TeamLogo
}

export type LoginUserMembership = Membership & {
  team: LoginUserTeam;
  invite?: MemberInvite;
  uiFlags: Record<string, unknown>;
  mentionedAiModelCodesAtLastThreadCreation?: string[],
};

// TODO: 下記のメソッド群をauth.util.tsに移す
export function getCurrentMembership(user?: LoginUser): LoginUserMembership | undefined {
  if (!user) {
    return undefined;
  }
  return user.memberships.find((m) => m.isCurrent);
}

export function getCurrentSubscription(user?: LoginUser): SubscriptionDetail | undefined {
  const membership = getCurrentMembership(user);
  return membership?.team.currentSubscription;
}

export function hasRole(user: LoginUser, role: MembershipRole) {
  const membership = getCurrentMembership(user);
  return membership?.role === role;
}

export function hasAdminAuth(user?: LoginUser) {
  if (!user) {
    return false;
  }
  return hasRole(user, MembershipRole.ADMIN) || hasRole(user, MembershipRole.SUPER_ADMIN);
}

export function hasSuperAdminAuth(user?: LoginUser) {
  if (!user) {
    return false;
  }
  return hasRole(user, MembershipRole.SUPER_ADMIN);
}

export function isSystemSupervisor(user?: LoginUser) {
  if (!user) {
    return false;
  }
  return !!user.supervisor;
}

export function getJoinMemberships(user: LoginUser): LoginUserMembership[] {
  return user.memberships.filter((m) => m.isJoined);
}

export function getUiFlagValue(user: LoginUser | undefined, key: string, defaultValue: unknown = null): unknown | undefined | null {
  const membership = getCurrentMembership(user);
  return membership?.uiFlags?.[key] ?? defaultValue;
}

export function getUsableAiModelConsts(user?: LoginUser): AIModelConst[] {
  const membership = getCurrentMembership(user);
  if (!membership) {
    return [];
  }
  const usableAiModelCodes = membership.team.quota.usableAiCodes || []
  return AIModelConsts.filter((model) => {
    return usableAiModelCodes.includes(model.id) && !model.deprecated
  });
}