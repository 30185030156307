import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginPresenter from '../presenter/LoginPresenter';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../auth.slice';
import { RootState } from '@/store';
import useLoading from '../../generic/hooks/useLoading';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { firebaseAuth } from '@/lib/firebase';

const LoginContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { setMessageModal } = useMessageModal();
  const { t, i18n } = useTranslation();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const redirectUrl = useSelector((state: RootState) => state.auth.redirectUrl);
  const provider = new GoogleAuthProvider();

  /**
   * 共通処理: CIPから受け取ったidTokenを使ってバックエンドへログインし、
   * ユーザープロフィールを取得してReduxに保存する。
   */
  const doAuthLogin = async (cipIdToken: string) => {
    // バックエンドへIDトークン送信（セッションクッキー発行）
    await apiAxios.post(
      '/auth/login',
      {
        idToken: cipIdToken,
        lastLoginProvider: 'google.com',
        language: i18n.language,
      },
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );

    // ユーザープロフィール取得
    const profileResponse = await apiAxios.get('/auth/profile', { withCredentials: true });
    dispatch(setUserInfo(profileResponse.data));

    // ユーザープロフィールに含まれる言語設定があればi18nの言語を切り替える
    if (profileResponse.data.language) {
      i18n.changeLanguage(profileResponse.data.language);
    }

    // ログイン後のリダイレクト
    navigate(redirectUrl || '/');
  };

  /**
   * ログインエラーを処理する関数
   * @param error エラーオブジェクト
   */
  const handleLoginError = (error: any) => {
    captureException(error);

    // t: 一般的なログインエラー
    let errorTitle = t("auth:loginError");
    // t: ログインに失敗しました。しばらく時間をおいて再度お試しください。
    let errorMessage = t("auth:loginFailedTryAgainLater");

    // Firebase認証エラーコードに基づいたメッセージ
    if (error?.code) {
      switch (error.code) {
        case 'auth/invalid-email':
          // t: メールアドレスの形式が正しくありません
          errorMessage = t("auth:invalidEmailFormat");
          break;
        case 'auth/user-disabled':
          // t: このアカウントは無効化されています
          errorMessage = t("auth:accountDisabled");
          break;
        case 'auth/invalid-credential':
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          // t: メールアドレスまたはパスワードが正しくありません
          errorMessage = t("auth:invalidCredentials");
          break;
        case 'auth/too-many-requests':
          // t: ログイン試行回数が多すぎます。しばらく時間をおいて再度お試しください。
          errorMessage = t("auth:tooManyAttempts");
          break;
        case 'auth/network-request-failed':
          // t: ネットワークエラーが発生しました。インターネット接続を確認してください。
          errorMessage = t("auth:networkError");
          break;
        default:
          // デフォルトのエラーメッセージを使用
          break;
      }
    }

    setMessageModal(errorTitle, errorMessage);
    console.error('ログインエラー:', error);
  };

  /**
   * Googleログイン（Firebase Auth）→ Googleの認証結果をCIPに渡してCIPトークン取得 → doAuthLogin
   */
  const handleGoogleLogin = async () => {
    setIsLoading(true);
    try {
      // GoogleログインをFirebase Authで実行（ポップアップ方式）
      const result = await signInWithPopup(firebaseAuth, provider);
      const idToken = await result.user.getIdToken();

      // 共通処理: CIPで取得したトークンを使ってバックエンドへログイン
      await doAuthLogin(idToken);
    } catch (error) {
      handleLoginError(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * ID・パスワードでのログイン
   */
  const handleIdPassLogin = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      // CIPを使ったID・パスワードログインでCIPのidTokenを取得
      const result = await signInWithEmailAndPassword(
        firebaseAuth, email, password
      )
      const idToken = await result.user.getIdToken();

      // 共通処理: CIPで取得したトークンを使ってバックエンドへログイン
      await doAuthLogin(idToken);
    } catch (error) {
      handleLoginError(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * すでにセッションがある場合、プロフィールを取得してReduxに格納し、トップページへ遷移
   */
  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      try {
        const response = await apiAxios.get('/auth/profile', { withCredentials: true });
        dispatch(setUserInfo(response.data));
        if (response.data.language) {
          i18n.changeLanguage(response.data.language);
        }
        navigate('/');
      } catch (error) {
        console.debug('/auth/profile エラー', error);
      } finally {
        setIsLoading(false);
      }
    };

    // トップレベルで取得した isAuthenticated を利用する
    if (!isAuthenticated) {
      checkAuth();
    }
  }, [isAuthenticated, dispatch, i18n, navigate, setIsLoading]);

  return (
    <LoginPresenter
      // 既存のGoogleログイン用
      loginWithGoogle={handleGoogleLogin}
      // 追加: ID・パスワードログイン用
      loginWithIdPass={handleIdPassLogin}
    />
  );
};

export default LoginContainer;
