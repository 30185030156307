import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoTypeConfigListPresenter from '../presenters/InfoTypeConfigListPresenter';
import { RootState } from '@/store';
import { DlpInfoType, DlpInfoTypeConfig, BulkSaveDlpInfoTypeConfig } from '../dlp.type';
import apiAxios from '@/lib/axios';
import { setInfoTypes, setInfoTypeConfigs } from '../dlp.slice';
import { useToast } from '../../generic/hooks/useToast';
import { captureException } from '@sentry/react';
import { useMessageModal } from '../../generic/hooks/useMessageModal';
import { useTranslation } from 'react-i18next';

const InfoTypeConfigListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const infoTypes = useSelector((state: RootState) => state.dlp.infoTypes);
  const infoTypeConfigs = useSelector((state: RootState) => state.dlp.infoTypeConfigs);
  const { setUnknownErrorMessageModal } = useMessageModal();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [infoTypesResponse, infoTypeConfigsResponse] = await Promise.all([
          apiAxios.get<DlpInfoType[]>('/dlp/info-types'),
          apiAxios.get<DlpInfoTypeConfig[]>('/dlp/info-type-configs'),
        ]);
        dispatch(setInfoTypes(infoTypesResponse.data));
        dispatch(setInfoTypeConfigs(infoTypeConfigsResponse.data));
      } catch (error) {
        captureException(error);
        setUnknownErrorMessageModal();
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleSave = async (data: BulkSaveDlpInfoTypeConfig) => {
    try {
      const response = await apiAxios.post<DlpInfoTypeConfig[]>('/dlp/info-type-configs/bulk', data);
      // t:保存しました
      showToast(t('common:message.saved'), 'success')
      dispatch(setInfoTypeConfigs(response.data));
    } catch (error) {
      captureException(error);
      setUnknownErrorMessageModal();
      console.error('Failed to save data:', error);
    }
  };

  return (
    <InfoTypeConfigListPresenter
      infoTypes={infoTypes}
      infoTypeConfigs={infoTypeConfigs}
      onSave={handleSave}
    />
  );
};

export default InfoTypeConfigListContainer;
